import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { EventPropTypes } from '../../../utils/useEvent';
import RSVPNumber from './RSVPNumber';
import { useCurrentUser } from '../../../context/UserContext';
import RSVPVerification from './RSVPVerification';
import RSVPName from './RSVPName';
import RSVPConfirmation from './RSVPConfirmation';

const steps = [RSVPNumber, RSVPVerification, RSVPName, RSVPConfirmation];
const LOGGED_IN_STEP = 2;

export default function RSVPForm({ event, refetchEvent }) {
  const [step, setStep] = useState(0);
  const [data, setData] = useState({ status: 'YES' });
  const { user, loading: userLoading } = useCurrentUser();

  const isSignedIn = !userLoading && Boolean(user);

  if (isSignedIn && step < LOGGED_IN_STEP) {
    setStep(LOGGED_IN_STEP);
  }

  const Step = steps[step];
  return (
    <Step
      event={event}
      data={data}
      refetchEvent={refetchEvent}
      onNextStep={(newData = {}) => {
        setData({ ...data, ...newData });
        setStep(step + 1);
      }}
    />
  );
}

RSVPForm.propTypes = {
  event: EventPropTypes.isRequired,
  refetchEvent: PropTypes.func,
};
