import React from 'react';
import {
  Box,
  Card,
  CardContent,
  makeStyles,
  Typography,
} from '@material-ui/core';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PhoneIcon from '../Icons/Phone';
import { PAGE_HEIGHT } from '../../constants';

const useStyles = makeStyles(() => ({
  main: {
    backgroundColor: '#dcf7f4',
    minHeight: PAGE_HEIGHT,
    padding: '33px 28px',
  },
  subHeader: {
    marginTop: '24px',
    marginBottom: '20px',
  },
  cardTitle: {
    margin: '10px 0',
  },
  secondaryCard: {
    marginBottom: '14px',
  },
}));

export default function Support() {
  const classes = useStyles();

  return (
    <Box className={classes.main}>
      <Typography variant="h1">Support</Typography>
      <Typography variant="h3" component="h2" className={classes.subHeader}>
        Have a question or comment?
      </Typography>
      <Card className={classes.secondaryCard}>
        <CardContent>
          <MailOutlineIcon style={{ height: '32px', width: '32px' }} />
          <Typography className={classes.cardTitle} variant="h3">
            Email Support
          </Typography>
          <Typography>
            <a href="mailto:support@differential.com">
              support@differential.com
            </a>
          </Typography>
        </CardContent>
      </Card>
      <Card className={classes.secondaryCard}>
        <CardContent>
          <PhoneIcon style={{ height: '32px', width: '32px' }} />
          <Typography className={classes.cardTitle} variant="h3">
            Phone Support
          </Typography>
          <Typography>
            <a href="tel:844-903-3900">(844) 903-3900</a>
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
}
