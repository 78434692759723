import React from 'react';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import { Box, Container, Typography } from '@material-ui/core';
import step1 from '../../img/copy-link-step-1.svg';
import step2 from '../../img/copy-link-step-2.svg';

export default function CopyLinkTutorial() {
  return (
    <Container style={{ display: 'flex', marginBottom: '-18px' }}>
      <Box display="flex" flexDirection="row" flex={1}>
        <Typography
          color="primary"
          variant="h3"
          style={{ marginRight: '5px', lineHeight: '24px' }}
        >
          step
        </Typography>
        <RadioButtonUncheckedIcon color="primary" />
        <Typography
          color="primary"
          variant="h6"
          style={{
            marginTop: '3px',
            marginLeft: '-14px',
            marginRight: '14px',
          }}
        >
          1
        </Typography>
        <Typography color="primary" variant="h5">
          copy
        </Typography>
        <img
          src={step1}
          alt="Copy Link Step 1"
          style={{ marginTop: '10px', marginLeft: '5px' }}
        />
      </Box>
      <Box display="flex" flexDirection="row" flex={1}>
        <Typography
          color="primary"
          variant="h3"
          style={{ marginRight: '5px', lineHeight: '24px' }}
        >
          step
        </Typography>
        <RadioButtonUncheckedIcon color="primary" />
        <Typography
          color="primary"
          variant="h6"
          style={{
            marginTop: '3px',
            marginLeft: '-16px',
            marginRight: '16px',
          }}
        >
          2
        </Typography>
        <Typography color="primary" variant="h5">
          share
        </Typography>
        <img
          src={step2}
          alt="Copy Link Step 2"
          style={{ marginTop: '-60px', marginLeft: '5px', zIndex: 1200 }}
        />
      </Box>
    </Container>
  );
}
