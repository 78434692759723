import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import Message from './Message';

const useStyles = makeStyles({
  list: {
    padding: '22px',
  },
  listItem: {
    width: '100%',
  },
});

export default function MessageList({ messages, isPrivate }) {
  const classes = useStyles();
  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      className={classes.list}
    >
      {messages.map((m) => (
        <Grid item key={m._id} className={classes.listItem}>
          <Message
            sender={m.sender}
            recipient={m.recipient}
            sentAt={m.sentAt}
            text={m.originalText}
            mediaUrls={m.mediaUrls}
            userIsSender={m.userIsSender}
            isPrivate={isPrivate}
          />
        </Grid>
      ))}
    </Grid>
  );
}

MessageList.propTypes = {
  loading: PropTypes.bool,
  messages: PropTypes.arrayOf(PropTypes.object),
  isPrivate: PropTypes.bool,
};
