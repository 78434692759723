import { SvgIcon } from '@material-ui/core';
import React from 'react';

export default function HashIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M3.33337 7.5H16.6667"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.33337 12.5H16.6667"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.33329 2.5L6.66663 17.5"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3333 2.5L11.6666 17.5"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
