import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import { Box } from '@material-ui/core';
import { ENV } from '../../constants';
import BackButton from '../Controls/BackButton';
import { authenticatedPost } from '../../utils/authenticatedFetch';
import FollowerList from '../FollowerList/FollowerList';
import useFollowers from '../../utils/useFollowers';
import Header from '../Header';
import EditButton from '../Controls/EditButton';
import PageLoader from '../PageLoader';
import { useCurrentUser } from '../../context/UserContext';

const post = async (endpoint, data) => {
  try {
    const response = await authenticatedPost(
      `${ENV.API_URL}/group/${endpoint}`,
      data
    );
    const result = await response.json();
    return result;
  } catch (err) {
    return false;
  }
};

const postAllowReply = async (groupId, userId, allowReply) => {
  return post('setAllowReply', { groupId, userId, allowReply });
};

const postDefaultAllowReply = async (groupId, allowReply) => {
  return post('setDefaultAllowReply', { groupId, allowReply });
};

export default function GroupViewDetails({ group, onGroupUpdate }) {
  const { _id: id } = group;
  const history = useHistory();
  const [defaultReply, setDefaultReply] = useState(group.defaultAllowReply);
  const [followers, setFollowers] = useFollowers(id);
  const { enqueueSnackbar } = useSnackbar();
  const { loading, user } = useCurrentUser();
  const userIsOwner = !loading && user._id === group.createdBy;

  useEffect(() => {
    if (followers.error)
      enqueueSnackbar('Failed to load followers', { variant: 'error' });
  }, [followers, enqueueSnackbar]);

  function handleBackClick() {
    const newPath = history.location.pathname.substr(
      0,
      history.location.pathname.lastIndexOf('/')
    );
    history.push(`${newPath}`);
  }

  const [saving, setSaving] = useState(false);
  const onAllowReplyToggleClick = async (userId, allowReply) => {
    if (saving) return;

    setSaving(true);
    // Toggle user's local value for intant feedback
    setFollowers((prevFollowers) =>
      prevFollowers.map((f) => (f._id === userId ? { ...f, allowReply } : f))
    );

    const result = await postAllowReply(id, userId, allowReply);

    if (!result.success) {
      // undo change and display error message
      setFollowers((prevFollowers) =>
        prevFollowers.map((f) =>
          f._id === userId ? { ...f, allowReply: !allowReply } : f
        )
      );

      enqueueSnackbar('Failed to save change', { variant: 'error' });
    } else {
      const feedback = allowReply ? 'enabled' : 'disabled';

      enqueueSnackbar(`User reply ${feedback}`, { variant: 'success' });
    }
    setSaving(false);
  };

  const [savingDefault, setSavingDefault] = useState(false);
  const onDefaultAllowReplyToggleClick = async (allowReply) => {
    if (savingDefault) return;

    setSavingDefault(true);
    // Toggle local value for intant feedback
    setDefaultReply(allowReply);

    const result = await postDefaultAllowReply(id, allowReply);

    if (!result.success) {
      // undo change and display error message
      setDefaultReply(!allowReply);

      enqueueSnackbar('Failed to save change', { variant: 'error' });
    } else {
      enqueueSnackbar(`Group updated`, { variant: 'success' });
      onGroupUpdate({ ...group, defaultAllowReply: allowReply });
    }
    setSavingDefault(false);
  };

  return followers.loading ? (
    <PageLoader />
  ) : (
    <Box>
      <Header
        back
        title={`#${group.displayGroupName}`}
        action={userIsOwner &&
          <EditButton
            onClick={() => history.push(`${history.location.pathname}/edit`)}
          />
        }
      >
        <BackButton onClick={handleBackClick} />
      </Header>
      <FollowerList
        defaultSettingTip="Allow or deny new followers to send messages to the group thread."
        defaultAllowReply={defaultReply}
        followers={followers.followers}
        unregisteredFollowers={followers.unregisteredFollowers}
        onDefaultAllowReplyChange={onDefaultAllowReplyToggleClick}
        savingDefault={savingDefault}
        onFollowerAllowReplyChange={onAllowReplyToggleClick}
        savingFollower={saving}
        readOnly={!userIsOwner}
      />
    </Box>
  );
}

GroupViewDetails.propTypes = {
  group: PropTypes.shape({
    _id: PropTypes.string,
    groupName: PropTypes.string,
    displayGroupName: PropTypes.string,
    intro: PropTypes.string,
    followers: PropTypes.arrayOf(PropTypes.object),
    defaultAllowReply: PropTypes.bool,
    createdBy: PropTypes.string,
  }),
  onGroupUpdate: PropTypes.func,
};
