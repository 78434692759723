import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  makeStyles,
  OutlinedInput,
  Snackbar,
  Typography,
  useTheme,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import InputMask from 'react-input-mask';
import { post } from '../../utils/fetch';
import { ENV, SNACKBAR_DURATION } from '../../constants';
import validateNumber from '../../utils/validateNumber';
import { useCurrentUser } from '../../context/UserContext';
import { authenticatedPost } from '../../utils/authenticatedFetch';

const useStyles = makeStyles({
  main: {
    backgroundColor: 'white',
    padding: '18px 22px',
  },
  phoneInput: {
    margin: '20px 0',
    width: '100%',
  },
  codeInput: {
    margin: '20px 0',
    width: '130px',
  },
});

export default function SignupPhoneNumber() {
  const classes = useStyles();
  const [requested, setRequested] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    type: 'success',
  });

  return (
    <Box className={classes.main}>
      <Typography variant="h2" color="primary">
        Verify your Phone Number
      </Typography>
      {requested ? (
        <CodeEntry setSnackbar={setSnackbar} />
      ) : (
        <PhoneNumberEntry
          setRequested={setRequested}
          setSnackbar={setSnackbar}
        />
      )}
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={SNACKBAR_DURATION}
        open={snackbar.open}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert severity={snackbar.type}>{snackbar.message}</Alert>
      </Snackbar>
    </Box>
  );
}

SignupPhoneNumber.propTypes = {
  onNext: PropTypes.func,
};

function PhoneNumberEntry({ setRequested, setSnackbar }) {
  const classes = useStyles();
  const theme = useTheme();
  const [number, setNumber] = useState('');

  function onNumberChange(e) {
    const { value } = e.target;
    const matchedValues = value.match(/\d+/g);
    const updatedValue = matchedValues ? matchedValues.join('') : '';

    setNumber(updatedValue);
  }

  const { refetch } = useQuery(
    'sendLoginRequest',
    () => {
      return post(`${ENV.API_URL}/sendLoginUrl`, {
        phoneNumber: number,
        withUrl: false,
      });
    },
    {
      enabled: false,
      onSuccess: () => {
        setRequested(true);
      },
      onError: () => {
        setSnackbar({
          open: true,
          message: 'Something happened...',
          type: 'error',
        });
      },
    }
  );

  return (
    <Box>
      <Typography
        variant="body2"
        style={{ marginTop: '10px', color: theme.palette.gray.darker }}
      >
        To sign up, we need to verify your phone number by texting you a code.
      </Typography>
      <FormControl className={classes.phoneInput} variant="outlined">
        <InputLabel htmlFor="number">Phone Number</InputLabel>
        <OutlinedInput
          id="number"
          value={number}
          onChange={onNumberChange}
          label="Phone Number"
          required
          inputComponent={InputMask}
          inputProps={{
            mask: '(999) 999-9999',
          }}
        />
      </FormControl>
      <Button
        onClick={() => refetch()}
        color="primary"
        variant="contained"
        disabled={!validateNumber(number)}
        style={{ marginBottom: '10px' }}
        disableElevation
        fullWidth
      >
        Next
      </Button>
      <Box display="flex" justifyContent="center">
        <Typography
          variant="body2"
          style={{ color: theme.palette.gray.darker }}
        >
          Standard message & data rates may apply.
        </Typography>
      </Box>
    </Box>
  );
}

PhoneNumberEntry.propTypes = {
  setRequested: PropTypes.func,
  setSnackbar: PropTypes.func,
};

function isValidCode(code) {
  return code?.length === 8 && !Number.isNaN(Number(code));
}

function CodeEntry({ setSnackbar }) {
  const classes = useStyles();
  const theme = useTheme();
  const [code, setCode] = useState('');
  const [token, setToken] = useState('');

  const { loadUser } = useCurrentUser();

  const { refetch: login } = useQuery(
    'login',
    () =>
      authenticatedPost(`${ENV.API_URL}/login`, {
        token,
      }),
    {
      onSuccess: () => {
        loadUser();
      },
      enabled: false,
    }
  );

  const { refetch, data, isLoading } = useQuery(
    'verifyLogin',
    async () => {
      const response = await post(`${ENV.API_URL}/urls/code/${code}`);
      return response.json();
    },
    {
      enabled: false,
      onSuccess: () => {},
      onError: () => {
        setSnackbar({
          open: true,
          message: 'Something happened...',
          type: 'error',
        });
      },
    }
  );

  useEffect(() => {
    if (!isLoading && data) {
      setToken(data.data.token);
    }
  }, [data, isLoading]);

  useEffect(() => {
    if (token) {
      login();
    }
  }, [token, login]);

  return (
    <Box>
      <Typography
        variant="body2"
        style={{ marginTop: '10px', color: theme.palette.gray.darker }}
      >
        You should get a text with a 8-digit code. Enter your code here:
      </Typography>
      <Box display="flex" alignItems="center" justifyContent="center">
        <FormControl className={classes.codeInput} variant="outlined">
          <InputLabel htmlFor="code">Code</InputLabel>
          <OutlinedInput
            id="code"
            value={code}
            onChange={(event) => setCode(event.target.value)}
            label="Code"
            type="text"
            inputMode="numeric"
            autoComplete="one-time-code"
            pattern="\d{8}"
            required
          />
        </FormControl>
      </Box>
      {isValidCode(code) && (
        <Button
          onClick={() => refetch()}
          color="primary"
          variant="contained"
          style={{ marginBottom: '10px' }}
          disableElevation
          fullWidth
        >
          Continue to Sign Up
        </Button>
      )}
    </Box>
  );
}

CodeEntry.propTypes = {
  setSnackbar: PropTypes.func,
};
