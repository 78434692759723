import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import MessageListHeader from './MessageListHeader';
import MessageList from './MessageList';
import AmploTabs from '../Controls/AmploTabs';
import { PAGE_HEIGHT } from '../../constants';
import PageLoader from '../PageLoader';

function isRawMessageList(messages) {
  return messages[0]?.type === undefined;
}

export default function MessagerView({
  name,
  photo,
  showTabs,
  messages,
  loading,
  headerClick,
  emptyContent,
}) {
  const listRef = useRef(null);
  const [selectedList, setSelectedList] = useState('');
  const [visibleMessages, setVisibleMessages] = useState([]);
  const [isPrivate, setIsPrivate] = useState(false);

  function getTypes() {
    if (isRawMessageList(messages)) return '';

    return messages.map((m) => m.type);
  }

  function handleTabChange(index) {
    setSelectedList(messages[index].type);
  }

  useEffect(() => {
    if (isRawMessageList(messages)) {
      setVisibleMessages(messages);
    } else {
      const selected = messages.find((m) => m.type === selectedList);
      setVisibleMessages(selected?.messages || []);
      setIsPrivate(selected?.isPrivate);
    }
  }, [selectedList, messages]);

  useEffect(() => {
    if (loading === false) {
      if (!isRawMessageList(messages)) {
        // Select the first list of messages
        setSelectedList(messages[0].type);
      }
      setTimeout(() => listRef.current.scrollIntoView(false), 0);
    }
  }, [loading, messages, listRef]);

  const isVisibleMessages = !!visibleMessages && visibleMessages.length > 0;
  const isMessages =
    !!messages &&
    ((isRawMessageList(messages) && messages.length > 0) ||
      messages.some((m) => m?.messages?.length > 0));

  return (
    <Box style={{ minHeight: PAGE_HEIGHT, backgroundColor: 'white' }}>
      <Box
        style={{
          position: 'fixed',
          left: '0',
          right: '0',
          zIndex: '2',
          boxShadow: '0px 2px 9px rgba(0, 0, 0, 0.17)',
          backgroundColor: 'white',
        }}
      >
        <MessageListHeader
          name={name}
          photo={photo}
          headerClick={headerClick}
        />
        {showTabs && !loading && isMessages && (
          <AmploTabs tabs={getTypes()} onChange={handleTabChange} />
        )}
      </Box>
      <Box
        style={{
          zIndex: '1',
          bottom: 0,
          width: '100%',
        }}
        ref={listRef}
      >
        {loading ? (
          <PageLoader />
        ) : (
          <>
            <div style={{ height: showTabs ? '123px' : '75px' }}></div>
            {!loading && isVisibleMessages ? (
              <MessageList messages={visibleMessages} isPrivate={isPrivate} />
            ) : (
              emptyContent
            )}
          </>
        )}
      </Box>
    </Box>
  );
}

MessagerView.propTypes = {
  name: PropTypes.string,
  photo: PropTypes.string,
  showTabs: PropTypes.bool,
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      messages: PropTypes.arrayOf(PropTypes.object),
      isPrivate: PropTypes.bool,
    })
  ),
  loading: PropTypes.bool,
  headerClick: PropTypes.func,
  emptyContent: PropTypes.node,
};
