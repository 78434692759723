import React, { useState } from 'react';
import { useQuery } from 'react-query';
import {
  Box,
  Button,
  Grid,
  Snackbar,
  TextField,
  Typography,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { useHistory } from 'react-router-dom';
import InputMask from 'react-input-mask';
import {
  BOTTOM_BAR_OFFSET,
  ENV,
  PAGE_HEIGHT,
  SNACKBAR_DURATION,
} from '../constants';

import { post } from '../utils/fetch';
import validateNumber from '../utils/validateNumber';
import { useCurrentUser } from '../context/UserContext';

export default function Login() {
  const { user, loading } = useCurrentUser();
  const history = useHistory();
  const [phoneNumber, setPhoneNumber] = useState();
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    type: '',
  });

  const { refetch: sendLoginUrl } = useQuery(
    'sendLoginRequest',
    () => {
      post(`${ENV.API_URL}/sendLoginUrl`, {
        phoneNumber,
        referrer: history.location.state?.from?.pathname,
      });
    },
    {
      enabled: false,
      onSuccess: () => {
        history.push(`/magic/code`);
      },
      onError: () => {
        setSnackbar({
          open: true,
          message: 'Something happened...',
          type: 'error',
        });
      },
    }
  );

  const onChange = (e) => {
    const { value } = e.target;
    const matchedValues = value.match(/\d+/g);
    const updatedValue = matchedValues ? matchedValues.join('') : '';

    setPhoneNumber(updatedValue);
  };

  if (!loading && user) {
    history.push('/');
  }

  return (
    <Box>
      <Grid
        container
        style={{
          minHeight: PAGE_HEIGHT,
          padding: `20px 22px ${BOTTOM_BAR_OFFSET}`,
        }}
        direction="column"
        justify="center"
        alignItems="center"
      >
        <Grid item style={{ textAlign: 'center' }}>
          <Typography variant="h1" color="primary">
            Send Login Link
          </Typography>
          <TextField
            label="Enter Your Phone Number"
            name="phoneNumber"
            variant="outlined"
            margin="normal"
            onChange={onChange}
            InputProps={{
              inputComponent: InputMask,
              inputProps: {
                mask: '(999) 999-9999',
              },
            }}
            fullWidth
            required
          />
          <Button
            variant="contained"
            color="primary"
            disableElevation
            aria-label="Send Login Link"
            fullWidth
            onClick={() => {
              if (!phoneNumber || !validateNumber(phoneNumber)) {
                setSnackbar({
                  open: true,
                  message: 'Please enter a valid phone number!',
                });
              } else {
                sendLoginUrl();
              }
            }}
          >
            Send
          </Button>
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={SNACKBAR_DURATION}
        open={snackbar.open}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert severity="error">{snackbar.message}</Alert>
      </Snackbar>
    </Box>
  );
}
