import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Box, Button, Typography } from '@material-ui/core';
import { PAGE_HEIGHT } from '../constants';

export default function ErrorPage({ children }) {
  return (
    <Box
      display="flex"
      alignItems="center"
      flexDirection="column"
      style={{ height: PAGE_HEIGHT, paddingTop: '200px', margin: '0 20px' }}
    >
      {children}
    </Box>
  );
}

ErrorPage.propTypes = {
  children: PropTypes.any,
};

export function ErrorMessage({ message }) {
  return (
    <Typography variant="h2" color="error">
      {message}
    </Typography>
  );
}

ErrorMessage.propTypes = {
  message: PropTypes.string,
};

export function ErrorButtons({ children }) {
  return (
    <Box display="flex" justifyContent="center" style={{ width: '100%' }}>
      {children}
    </Box>
  );
}

ErrorButtons.propTypes = {
  children: PropTypes.any,
};

export function ErrorHome() {
  const history = useHistory();

  return (
    <Button
      onClick={() => history.push('/')}
      color="primary"
      variant="contained"
      style={{ margin: '10px 5px' }}
      disableElevation
    >
      Home
    </Button>
  );
}

export function ErrorBack() {
  const history = useHistory();

  return (
    <Button
      onClick={() => history.goBack()}
      color="primary"
      variant="contained"
      style={{ margin: '10px 5px' }}
      disableElevation
    >
      Back
    </Button>
  );
}
