import React from 'react';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import { Box, makeStyles, Typography } from '@material-ui/core';
import GuideCard from '../CreateSuccess/GuideCard';
import UserIcon from '../Icons/UserIcon';
import MessageIcon from '../Icons/MessageIcon';
import { useCurrentUser } from '../../context/UserContext';
import { ENV, PAGE_HEIGHT } from '../../constants';

const useStyles = makeStyles(() => ({
  main: {
    backgroundColor: '#dcf7f4',
    minHeight: PAGE_HEIGHT,
    padding: '33px 28px',
  },
  setUp: {
    marginBottom: '24px',
  },
  subHeader: {
    marginTop: '24px',
    marginBottom: '20px',
    textAlign: 'center',
  },
  iconWrapper: {
    margin: '14px 0 12px 0',
    '& *': {
      margin: '0 6px',
    },
  },
  secondaryCard: {
    textAlign: 'center',
    marginBottom: '14px',
  },
}));

export default function QuickGuides() {
  const { loading, user } = useCurrentUser();
  const classes = useStyles();

  return (
    <>
      <Box className={classes.main}>
        <Typography variant="h1" color="primary">
          Quick Guide
        </Typography>
        <Typography
          variant="h3"
          component="h2"
          className={classes.subHeader}
          color="primary"
        >
          Your Username
        </Typography>
        <GuideCard
          body={
            <>
              To send a message to your followers:{' '}
              <b>text your message to {ENV.SHORT_CODE}</b>
            </>
          }
          icons={
            <>
              <UserIcon multi style={{ fontSize: 42 }} />
              <MessageIcon style={{ fontSize: 52 }} />
            </>
          }
        />
        {user?.displayHandle && (
          <GuideCard
            body={
              <>
                People can follow you by texting{' '}
                <b>
                  @{loading ? 'username' : user.displayHandle} to{' '}
                  {ENV.SHORT_CODE}
                </b>
              </>
            }
            icons={
              <>
                <UserIcon check style={{ fontSize: 42 }} />
                <MessageIcon at flip style={{ fontSize: 52 }} />
              </>
            }
          />
        )}
        <GuideCard
          body={
            <>
              To direct message someone:{' '}
              <b>text their @[username] + your message to {ENV.SHORT_CODE}</b>
            </>
          }
          icons={
            <>
              <UserIcon style={{ fontSize: 42 }} />
              <ArrowBackRoundedIcon
                style={{ fontSize: 24, margin: '0 -7px 17px -17px' }}
              />
              <MessageIcon at style={{ fontSize: 52 }} />
            </>
          }
        />
        <Typography
          variant="h3"
          component="h2"
          color="primary"
          className={classes.subHeader}
        >
          Groups
        </Typography>
        <GuideCard
          body={
            <>
              To send a message to group followers:{' '}
              <b>text #[groupname] + your message to {ENV.SHORT_CODE}</b>
            </>
          }
          icons={
            <>
              <UserIcon multi style={{ fontSize: 42 }} />
              <MessageIcon style={{ fontSize: 52 }} />
            </>
          }
        />
        <GuideCard
          body={
            <>
              People can follow the group by texting{' '}
              <b>#[groupname] to {ENV.SHORT_CODE}</b>
            </>
          }
          icons={
            <>
              <MessageIcon style={{ fontSize: 52 }} />
              <UserIcon check style={{ fontSize: 42 }} />
            </>
          }
        />
      </Box>
    </>
  );
}
