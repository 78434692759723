import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useMutation } from 'react-query';
import { useSnackbar } from 'notistack';
import { Box, makeStyles, Typography } from '@material-ui/core';
import SignupAbout from './SignupAbout';
import SignupHandle from './SignupHandle';
import SignupIntro from './SignupIntro';
import { ENV, ERRORS } from '../../constants';
import toBase64 from '../../utils/toBase64';
import AmploSteps from '../Controls/AmploSteps';
import { useCurrentUser } from '../../context/UserContext';
import { authenticatedPost } from '../../utils/authenticatedFetch';

const steps = [
  {
    title: 'About',
    content: 'about',
    form: SignupAbout,
  },
  {
    title: 'Handle',
    content: 'handle',
    form: SignupHandle,
  },
  {
    title: 'Intro Message',
    content: 'intro',
    form: SignupIntro,
  },
];

const useStyles = makeStyles({
  main: {
    backgroundColor: 'white',
    padding: '20px 27px',
  },
});

export default function SignupForm() {
  const classes = useStyles();
  const history = useHistory();
  const { user } = useCurrentUser();
  const { enqueueSnackbar } = useSnackbar();
  const [formValues, setFormValues] = useState({
    firstName: user.firstName,
    lastName: user.lastName,
    handle: user.displayHandle,
    email: user.email,
    photo: user.photo,
    intro: user.intro,
  });
  const [currentStep, setCurrentStep] = useState(0);
  const step = steps[currentStep];

  const onSubmit = useMutation(
    async (newFormValues) => {
      setFormValues({
        ...formValues,
        ...newFormValues,
      });
      const formValuesCopy = { ...formValues, ...newFormValues };

      const fileFormValues = Object.keys(formValuesCopy).filter(
        (key) => formValuesCopy[key] instanceof File
      );

      // Convert any Files to
      await Promise.all(
        fileFormValues.map(async (key) => {
          const value = formValuesCopy[key];
          formValuesCopy[key] = await toBase64(value);
        })
      );

      return authenticatedPost(`${ENV.API_URL}/signup`, formValuesCopy);
    },
    {
      onSuccess: async (response) => {
        if (response.status === 500) {
          const result = await response.text();
          enqueueSnackbar(result, { variant: 'error' });
        } else {
          history.push(`/signup/success`);
        }
      },
      onError: () => {
        history.push(`/signup/error`, {
          error: ERRORS.SIGNUP.CREATE_FAILURE,
        });
      },
    }
  );

  const onPrevious = () => {
    setCurrentStep(currentStep - 1);
  };

  const onNext = (newFormValues) => {
    setFormValues({
      ...formValues,
      ...newFormValues,
    });

    setCurrentStep(currentStep + 1);
  };

  return (
    <Box className={classes.main}>
      <Typography variant="h2" style={{ marginBottom: '20px' }}>
        Sign Up
      </Typography>
      <AmploSteps
        currentStep={currentStep}
        stepCount={steps.length}
        style={{ marginBottom: '13px' }}
      />
      <step.form
        onPrevious={onPrevious}
        onNext={onNext}
        onSubmit={onSubmit.mutate}
        formValues={formValues}
      />
    </Box>
  );
}

SignupForm.propTypes = {
  id: PropTypes.string,
};
