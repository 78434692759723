import { useEffect, useState } from 'react';
import { ENV } from '../constants';

/**
 * Provides access to all the Groups a user belongs to, either by following and creating
 *
 * @param {ObjectId} userId
 */
export default function useGroupList(userId) {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});

  useEffect(() => {
    let mounted = true;
    setLoading(true);

    const load = async () => {
      try {
        const response = await fetch(
          `${ENV.API_URL}/user/${userId}/groups?all=true`,
          {
            method: 'GET',
            credentials: 'include',
          }
        );

        const groupResponse = await response.json();
        if (mounted) {
          if (!groupResponse.userGroups) {
            throw new Error();
          }

          setData(groupResponse.userGroups);
          setLoading(false);
        }
      } catch (err) {
        if (mounted) {
          setError(true);
          setLoading(false);
        }
      }
    };

    if (userId) load();

    return () => {
      mounted = false;
      setLoading(false);
    };
  }, [userId, setLoading, setError]);

  return { error, loading, data };
}
