import React, { useEffect } from 'react';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import CreateSuccess from '../CreateSuccess/CreateSuccess';
import GuideCard from '../CreateSuccess/GuideCard';
import UserIcon from '../Icons/UserIcon';
import MessageIcon from '../Icons/MessageIcon';
import { useCurrentUser } from '../../context/UserContext';
import { ENV } from '../../constants';
import PageLoader from '../PageLoader';

export default function SignupSuccess() {
  const { user, loading, loadUser } = useCurrentUser();

  useEffect(() => {
    loadUser();
  }, [loadUser]);

  if (loading) return <PageLoader />;

  const { displayHandle } = user;
  const name = `@${displayHandle}`;

  return (
    <CreateSuccess
      name={name}
      message="You're all set up!"
      cta="to receive texts from you."
      quickGuide="Using amplo"
    >
      <GuideCard
        body={
          <>
            To send a message to your followers:{' '}
            <b>text your message to {ENV.SHORT_CODE}</b>
          </>
        }
        icons={
          <>
            <UserIcon multi style={{ fontSize: 42 }} />
            <MessageIcon style={{ fontSize: 52 }} />
          </>
        }
      />
      <GuideCard
        body={
          <>
            People can follow you by texting{' '}
            <b>
              {name} to {ENV.SHORT_CODE}
            </b>
          </>
        }
        icons={
          <>
            <UserIcon check style={{ fontSize: 42 }} />
            <MessageIcon at flip style={{ fontSize: 52 }} />
          </>
        }
      />
      <GuideCard
        body={
          <>
            To direct message someone that follows you or that you follow:{' '}
            <b>text their @[username] + your message to {ENV.SHORT_CODE}</b>
          </>
        }
        icons={
          <>
            <UserIcon style={{ fontSize: 42 }} />
            <ArrowBackRoundedIcon
              style={{ fontSize: 24, margin: '0 -7px 17px -17px' }}
            />
            <MessageIcon at style={{ fontSize: 52 }} />
          </>
        }
      />
    </CreateSuccess>
  );
}
