import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardContent,
  makeStyles,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  iconWrapper: {
    margin: '14px 0 12px 0',
    '& *': {
      margin: '0 6px',
    },
  },
  secondaryCard: {
    marginBottom: '14px',
    textAlign: 'center',
  },
}));

export default function GuideCard({ body, icons }) {
  const classes = useStyles();

  return (
    <Card className={classes.secondaryCard}>
      <CardContent>
        <Box className={classes.iconWrapper}>{icons}</Box>
        <Typography>{body}</Typography>
      </CardContent>
    </Card>
  );
}

GuideCard.propTypes = {
  body: PropTypes.node,
  icons: PropTypes.node,
};
