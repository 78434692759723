import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  makeStyles,
  OutlinedInput,
  TextField,
} from '@material-ui/core';
import { ENV } from '../../constants';
import { authenticatedPost } from '../../utils/authenticatedFetch';
import Header from '../Header';

const postUpdate = async (id, data) => {
  try {
    const response = await authenticatedPost(
      `${ENV.API_URL}/group/${id}/update`,
      data
    );
    const result = await response.json();
    return result;
  } catch (err) {
    console.error(err);
    return false;
  }
};

const useStyles = makeStyles((theme) => ({
  form: {
    backgroundColor: 'white',
    padding: '20px',
    paddingTop: 0,
  },
  formItem: {
    paddingBottom: '20px',
    width: '100%',
  },
  success: {
    color: theme.palette.success.main,
  },
  error: {
    color: theme.palette.error.main,
  },
}));

export default function GroupEdit({ group, setGroup }) {
  const { id } = useParams();
  const history = useHistory();
  const [validity, setValidity] = useState({ validity: '', message: '' });
  const [groupName, setGroupName] = useState(group.displayGroupName);
  const [groupIntro, setGroupIntro] = useState(group.intro);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  function onCancelClick() {
    history.push(`/group/${id}/details`);
  }

  const [saving, setSaving] = useState(false);
  async function onSubmit() {
    setSaving(true);
    const intro =
      groupIntro || `Thanks for joining #${group.displayGroupName}.`;
    const result = await postUpdate(id, {
      groupId: id,
      name: groupName,
      intro,
    });

    if (!result.success) {
      enqueueSnackbar(`Failed to save change, try again later`, {
        variant: 'error',
      });
      return;
    }

    enqueueSnackbar('Group updated', { variant: 'success' });
    setSaving(false);
    setGroup({ ...group, displayGroupName: groupName, intro });

    history.push(`/group/${id}/details`);
  }

  useEffect(() => {
    const controller = new AbortController();
    const nameValidationTimeout = setTimeout(async () => {
      if (!groupName) {
        setValidity({ validity: 'error', message: 'Please input a name!' });
        return;
      }

      if (group.displayGroupName === groupName) {
        setValidity({
          validity: '',
          message: '',
        });
      } else {
        const body = { groupName };
        setValidity({ validity: '', message: '' });
        try {
          const isValid = await authenticatedPost(
            `${ENV.API_URL}/group/validateGroupName`,
            body,
            { signal: controller.signal }
          );

          const response = await isValid.json();

          setValidity({
            validity: response.validity,
            message: response.message,
          });
        } catch (err) {
          console.error(err);
        }
      }
    }, 1000);

    return () => {
      clearTimeout(nameValidationTimeout);
      controller.abort();
    };
  }, [groupName, group.displayGroupName]);

  const formValid =
    validity.validity === 'success' ||
    (validity.validity !== 'error' && groupIntro !== group.intro);

  return (
    <Box>
      <Header back title="Edit Group" />
      <form onSubmit={onSubmit} className={classes.form}>
        <FormControl variant="outlined" className={classes.formItem}>
          <InputLabel htmlFor="group-name">Group Name</InputLabel>
          <OutlinedInput
            id="event-name"
            value={groupName}
            onChange={(event) => setGroupName(event.target.value)}
            label="Group Name"
          />
          {validity.validity && (
            <FormHelperText
              className={classes[validity.validity]}
              id="group-name-valid"
            >
              {validity.message}
            </FormHelperText>
          )}
        </FormControl>
        <FormControl className={classes.formItem}>
          <TextField
            id="group-intro"
            value={groupIntro}
            onChange={(event) => setGroupIntro(event.target.value)}
            label="Intro Message"
            placeholder={`Thanks for joining #${group.displayGroupName}.`}
            InputLabelProps={{ shrink: true }}
            multiline
            rows={4}
            variant="outlined"
          />
        </FormControl>
        <Box style={{ display: 'flex', paddingBottom: 10, width: '100%' }}>
          <Button
            style={{ marginRight: 5, flex: 1 }}
            variant="contained"
            onClick={onCancelClick}
            disableElevation
          >
            Cancel
          </Button>
          <Button
            style={{ marginLeft: 5, flex: 1 }}
            variant="contained"
            color="primary"
            onClick={() => onSubmit()}
            disabled={!formValid && !saving}
            disableElevation
          >
            Save Changes
          </Button>
        </Box>
      </form>
    </Box>
  );
}

GroupEdit.propTypes = {
  group: PropTypes.shape({
    _id: PropTypes.string,
    groupName: PropTypes.string,
    displayGroupName: PropTypes.string,
    intro: PropTypes.string,
    followers: PropTypes.arrayOf(PropTypes.object),
    defaultAllowReply: PropTypes.bool,
  }),
  setGroup: PropTypes.func,
};
