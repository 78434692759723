import React from 'react';
import { Redirect } from 'react-router-dom';
import SignupForm from './SignupForm';
import { useCurrentUser } from '../../context/UserContext';
import SignupPhoneNumber from './SignupPhoneNumber';

export default function Signup() {
  const { user, loading } = useCurrentUser();

  if (loading) {
    return null;
  }

  // If the user has a handle
  if (user && user.handle) {
    return <Redirect to={`/user/${user._id}/details/edit`} />;
  }

  return user ? <SignupForm /> : <SignupPhoneNumber />;
}
