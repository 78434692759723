import React from 'react';
import { ERRORS } from '../../constants';
import ErrorPage, { ErrorHome, ErrorButtons, ErrorMessage } from '../ErrorPage';

export default function GroupNotFound() {
  return (
    <ErrorPage>
      <ErrorMessage message={ERRORS.GROUP_VIEW.NOT_FOUND} />
      <ErrorButtons>
        <ErrorHome />
      </ErrorButtons>
    </ErrorPage>
  );
}
