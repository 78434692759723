import React, { useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  makeStyles,
  Typography,
} from '@material-ui/core';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import RemoveRoundedIcon from '@material-ui/icons/RemoveRounded';
import { FAQ as FAQ_CONTENT, PAGE_HEIGHT } from '../../constants';

const useStyles = makeStyles(() => ({
  main: {
    minHeight: PAGE_HEIGHT,
    padding: '33px 28px',
  },
  subHeader: {
    marginTop: '24px',
    marginBottom: '20px',
  },
  image: {
    width: '100%',
    marginTop: '1em',
  },
  accordion: {
    alignItems: 'flex-start',
  },
}));

export default function FAQ() {
  const classes = useStyles();
  const [openPanel, setOpenPanel] = useState('');

  const handleChange = (index) => (_, isExpanded) => {
    setOpenPanel(isExpanded ? index : '');
  };

  const usernamePanels = FAQ_CONTENT.FAQ_USERNAME;
  const groupsPanels = FAQ_CONTENT.FAQ_GROUPS;
  const followingUsernamePanels = FAQ_CONTENT.FAQ_FOLLOWING_USERNAME;
  const followingGroupsPanels = FAQ_CONTENT.FAQ_FOLLOWING_GROUP;
  const eventsPanels = FAQ_CONTENT.FAQ_EVENTS;

  function printPanels(panels, name) {
    return panels.map((a, i) => (
      <Accordion
        key={i}
        expanded={openPanel === i + name}
        onChange={handleChange(i + name)}
      >
        <AccordionSummary
          expandIcon={
            openPanel === i + name ? <RemoveRoundedIcon /> : <AddRoundedIcon />
          }
          aria-controls={`panelu${i}-content`}
          id={`panelu${1}-header`}
          className={classes.accordion}
        >
          <Typography variant="h5">{a.title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            {a.body}
            {a.image ? (
              <img src={a.image} alt={a.imageAlt} className={classes.image} />
            ) : null}
          </Typography>
        </AccordionDetails>
      </Accordion>
    ));
  }

  return (
    <Box className={classes.main}>
      <Typography variant="h1">FAQs</Typography>
      <Typography variant="h3" component="h2" className={classes.subHeader}>
        Username Questions
      </Typography>
      {printPanels(usernamePanels, 'username')}
      <Typography variant="h3" component="h2" className={classes.subHeader}>
        Group Questions
      </Typography>
      {printPanels(groupsPanels, 'groups')}
      <Typography variant="h3" component="h2" className={classes.subHeader}>
        Following a Username
      </Typography>
      {printPanels(followingUsernamePanels, 'followingUsername')}
      <Typography variant="h3" component="h2" className={classes.subHeader}>
        Following a Group
      </Typography>
      {printPanels(followingGroupsPanels, 'followingGroups')}
      <Typography variant="h3" component="h2" className={classes.subHeader}>
        Events
      </Typography>
      {printPanels(eventsPanels, 'events')}
    </Box>
  );
}
