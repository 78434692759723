import React, { useState } from 'react';
import { useQuery } from 'react-query';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  makeStyles,
  OutlinedInput,
  Snackbar,
  Typography,
  useTheme,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import InputMask from 'react-input-mask';
import { post } from '../../../utils/fetch';
import { ENV, SNACKBAR_DURATION } from '../../../constants';
import validateNumber from '../../../utils/validateNumber';

const useStyles = makeStyles({
  main: {
    backgroundColor: 'white',
    padding: '18px 22px',
  },
  textInput: {
    margin: '20px 0',
    width: '100%',
  },
});

export default function RSVPNumber({ onNextStep }) {
  const classes = useStyles();
  const theme = useTheme();
  const [number, setNumber] = useState('');
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    type: 'success',
  });

  function onNumberChange(e) {
    const { value } = e.target;
    const matchedValues = value.match(/\d+/g);
    const updatedValue = matchedValues ? matchedValues.join('') : '';

    setNumber(updatedValue);
  }

  const { refetch } = useQuery(
    'sendLoginRequest',
    () => {
      return post(`${ENV.API_URL}/sendLoginUrl`, {
        phoneNumber: number,
        withUrl: false,
      });
    },
    {
      enabled: false,
      onSuccess: () => {
        onNextStep();
      },
      onError: () => {
        setSnackbar({
          open: true,
          message: 'Something happened...',
          type: 'error',
        });
      },
    }
  );

  return (
    <Box className={classes.main}>
      <Typography color="primary" variant="h2">
        RSVP to This Event
      </Typography>
      <Typography
        variant="body2"
        style={{ marginBottom: '10px', color: theme.palette.gray.darker }}
      >
        In order to RSVP for an event and receive notifications about any
        changes, we just need your name and phone number.
      </Typography>
      <Typography variant="h6" style={{ color: theme.palette.gray.darker }}>
        To edit your RSVP,{' '}
        <Typography
          variant="body2"
          style={{ color: theme.palette.gray.darker, display: 'inline' }}
        >
          enter the phone number you have previously submitted and select your
          updated response.
        </Typography>
      </Typography>
      <FormControl className={classes.textInput} variant="outlined">
        <InputLabel htmlFor="number">Phone Number</InputLabel>
        <OutlinedInput
          id="number"
          value={number}
          onChange={onNumberChange}
          label="Phone Number"
          required
          inputComponent={InputMask}
          inputProps={{
            mask: '(999) 999-9999',
          }}
        />
      </FormControl>
      <Button
        onClick={() => refetch()}
        color="primary"
        variant="contained"
        disabled={!validateNumber(number)}
        style={{ width: '100%', marginBottom: '10px' }}
        disableElevation
      >
        Next
      </Button>
      <Box display="flex" justifyContent="center">
        <Typography
          variant="body2"
          style={{ color: theme.palette.gray.darker }}
        >
          Standard message & data rates may apply.
        </Typography>
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={SNACKBAR_DURATION}
        open={snackbar.open}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert severity={snackbar.type}>{snackbar.message}</Alert>
      </Snackbar>
    </Box>
  );
}

RSVPNumber.propTypes = {
  onNextStep: PropTypes.func,
};
