import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { makeStyles, Typography } from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';

const useStyles = makeStyles({
  text: {
    alignItems: 'center',
    display: 'flex',
    margin: 0,
  },
});

export default function EditButton({ onClick }) {
  const history = useHistory();
  const classes = useStyles();
  return (
    <Typography
      color="primary"
      variant="h6"
      className={classes.text}
      onClick={() => onClick(history)}
      // history.push(`/events/update/${id}`)}
    >
      Edit{' '}
      <CreateIcon
        color="primary"
        fontSize="small"
        style={{ marginLeft: '4px' }}
      />
    </Typography>
  );
}

EditButton.propTypes = {
  onClick: PropTypes.func,
};
