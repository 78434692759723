import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  makeStyles,
  OutlinedInput,
  Snackbar,
  Typography,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import { ENV, EVENTS, SNACKBAR_DURATION } from '../../../constants';
import { useCurrentUser } from '../../../context/UserContext';
import { authenticatedPost } from '../../../utils/authenticatedFetch';
import { EventPropTypes } from '../../../utils/useEvent';
import EventRSVPStatus from '../EventRSVPStatus';

const useStyles = makeStyles((theme) => ({
  main: {
    backgroundColor: 'white',
    padding: '18px 22px',
  },
  textInput: {
    marginBottom: '20px',
    width: '100%',
  },
  button: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  maybeButton: {
    textDecoration: 'underline',
  },
  closeButton: {
    position: 'absolute',
    right: '0px',
    top: '0px',
    color: theme.palette.primary.main,
  },
  popup: {
    backgroundColor: theme.palette.secondary.lightDisabled,
  },
  popupSubtext: {
    color: theme.palette.gray.dark,
    fontSize: '12px',
    lineHeight: '14.4px',
    padding: '10px',
    textAlign: 'center',
  },
}));

export default function RSVPName({ event, onNextStep, refetchEvent }) {
  const { user, loadUser } = useCurrentUser();
  const classes = useStyles();
  const [rsvp, setRsvp] = useState();
  const [firstName, setFirstName] = useState(user.firstName || '');
  const [lastName, setLastName] = useState(user.lastName || '');
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    type: 'success',
  });

  const existingRsvp = event.users.find(
    (u) => u.id === user._id && Boolean(u.status)
  );
  const isUpdate = Boolean(existingRsvp);

  const submitRsvp = useMutation(
    (data) => {
      return authenticatedPost(`${ENV.API_URL}/events/rsvp`, {
        eventId: event._id,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        ...data,
      });
    },
    {
      onSuccess: async (data, variables) => {
        await loadUser();
        await refetchEvent();
        onNextStep({ status: variables.status, isUpdate, eventId: event._id });
      },
      onError: () => {
        setSnackbar({
          open: true,
          message: 'Something happened...',
          type: 'error',
        });
      },
    }
  );

  useEffect(() => {
    setFirstName(user.firstName || '');
    setLastName(user.lastName || '');
  }, [user]);

  const showNameFields = !user.firstName && !user.lastName;
  const buttonsDisabled = !(firstName && lastName);

  const title = existingRsvp ? `Update your RSVP` : 'RSVP to This Event';

  return (
    <Box className={classes.main}>
      <Box display="flex" flexDirection="column" alignItems="center">
        {existingRsvp ? (
          <EventRSVPStatus event={event} userId={user._id} />
        ) : null}
        <Typography
          color="primary"
          variant="h2"
          style={{ marginBottom: '20px' }}
        >
          {title}
        </Typography>
      </Box>
      {showNameFields && (
        <Box>
          <FormControl className={classes.textInput} variant="outlined">
            <InputLabel htmlFor="firstName">First Name</InputLabel>
            <OutlinedInput
              id="firstName"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              label="First Name"
            />
          </FormControl>
          <FormControl className={classes.textInput} variant="outlined">
            <InputLabel htmlFor="lastName">Last Name</InputLabel>
            <OutlinedInput
              id="lastName"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              label="Last Name"
            />
          </FormControl>
        </Box>
      )}
      <Box className={classes.formItem}>
        <Box style={{ display: 'flex', paddingBottom: 10, width: '100%' }}>
          <Button
            style={{
              marginRight: 5,
            }}
            className={classes.button}
            variant="contained"
            disabled={buttonsDisabled}
            onClick={() =>
              submitRsvp.mutate({
                notify: false,
                status: EVENTS.STATUS.NO,
                firstName,
                lastName,
              })
            }
          >
            {"No, can't make it."}
          </Button>
          <Button
            style={{ marginLeft: 5, flex: 1 }}
            className={classes.button}
            variant="contained"
            color="primary"
            disabled={buttonsDisabled}
            onClick={() => setRsvp(EVENTS.STATUS.YES)}
          >
            {"Yes, I'm going!"}
          </Button>
        </Box>
        <Button
          style={{ width: '100%' }}
          className={clsx(classes.button, classes.maybeButton)}
          disabled={buttonsDisabled}
          onClick={() => setRsvp(EVENTS.STATUS.MAYBE)}
        >
          {"Maybe, I'll decide later"}
        </Button>
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={SNACKBAR_DURATION}
        open={snackbar.open}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert severity={snackbar.type}>{snackbar.message}</Alert>
      </Snackbar>
      <Dialog
        classes={{ paper: classes.popup }}
        open={rsvp === EVENTS.STATUS.MAYBE || rsvp === EVENTS.STATUS.YES}
        onClose={() => setRsvp(null)}
      >
        <DialogTitle>
          <IconButton
            className={classes.closeButton}
            onClick={() => setRsvp(null)}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography
            color="primary"
            variant="h4"
            style={{ textAlign: 'center' }}
          >
            Would you like to be notified if this event changes?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              submitRsvp.mutate({
                notify: false,
                status: rsvp,
                firstName,
                lastName,
              });
            }}
            variant="contained"
            disableElevation
          >
            No
          </Button>
          <Button
            onClick={() => {
              submitRsvp.mutate({
                notify: true,
                status: rsvp,
                firstName,
                lastName,
              });
            }}
            color="primary"
            variant="contained"
            disableElevation
          >
            Yes
          </Button>
        </DialogActions>
        <Typography className={classes.popupSubtext}>
          Standard message & data rates may apply. Message Frequency varies.
        </Typography>
      </Dialog>
    </Box>
  );
}

RSVPName.propTypes = {
  event: EventPropTypes,
  onNextStep: PropTypes.func,
  refetchEvent: PropTypes.func,
};
