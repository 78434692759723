import { RightOutlined } from '@ant-design/icons';
import React from 'react';
import PropTypes from 'prop-types';
import { Box, Link, Typography } from '@material-ui/core';
import UserAvatar from '../GroupView/UserAvatar';

export default function MessageListHeader({
  name,
  photo,
  headerClick,
  transparent = false,
}) {
  return (
    <Box
      style={{
        width: '100%',
        backgroundColor: transparent ? 'rgba(0,0,0,0)' : 'white',
        fontSize: '20px',
        backdropFilter: 'blur(8px)',
      }}
    >
      <Link
        onClick={headerClick}
        style={{
          padding: '0 22px',
          height: '80px',
          color: 'rgba(0, 0, 0, 0.85)',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {photo && (
            <UserAvatar
              user={{ firstName: name, photo }}
              style={{ height: '52px', width: '52px', marginRight: '19px' }}
            />
          )}
          <Typography variant="subtitle2" color="primary">
            {name}
          </Typography>
        </Box>
        {headerClick && <RightOutlined />}
      </Link>
    </Box>
  );
}

MessageListHeader.propTypes = {
  name: PropTypes.string,
  photo: PropTypes.string,
  headerClick: PropTypes.func,
  transparent: PropTypes.bool,
};
