import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, useHistory } from 'react-router-dom';
import { useCurrentUser } from '../context/UserContext';

export default function PrivateRoute({ children, ...rest }) {
  const { user, loading } = useCurrentUser();
  const history = useHistory();

  if (loading) return null;

  return user ? (
    <Route {...rest}>{children}</Route>
  ) : (
    <Redirect to={{ pathname: '/login', state: { from: history.location } }} />
  );
}

PrivateRoute.propTypes = {
  children: PropTypes.node,
};
