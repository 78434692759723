import { Box, makeStyles, Typography } from '@material-ui/core';
import dayjs from 'dayjs';
import React from 'react';
import useEvents from '../../utils/useEvents';
import CopyLinkButton from '../Controls/CopyLinkButton';

const useStyles = makeStyles((theme) => ({
  eventLink: {
    backgroundColor: theme.palette.secondary.lightDisabled,
    boxShadow: '0px 2px 9px rgba(0, 0, 0, 0.17)',
    height: '108px',
    marginBottom: '16px',
    padding: '12px',
    paddingLeft: '24px',
  },
}));

export default function UpcomingEventsSelector() {
  const classes = useStyles();
  const events = useEvents({ limit: 3 });

  return !events.isLoading && events.data?.length > 0 ? (
    <Box flex="1">
      <Typography variant="h3" color="primary" style={{ marginBottom: '12px' }}>
        Upcoming Events to Link
      </Typography>
      <Box display="flex" flexDirection="column">
        {events.data.map((event) => {
          const eventStart = dayjs(event.startTime);
          const eventEnd = dayjs(event.endTime);
          return (
            <Box
              key={event._id}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              className={classes.eventLink}
            >
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                style={{ height: '100%' }}
              >
                <Typography variant="h4" color="primary">
                  {event.title}
                </Typography>
                <Typography variant="h5" color="primary">
                  {`${eventStart.format('h:mm a')} - ${eventEnd.format(
                    'h:mm a'
                  )}`}
                </Typography>
                <Typography variant="h5" color="primary">
                  {`${eventStart.format('ddd, MMM. D')}`}
                </Typography>
              </Box>
              <CopyLinkButton
                beforeText="Copy Link"
                afterText="Copied"
                url={`${document.location.origin}/events/event/${event._id}`}
                style={{
                  margin: 0,
                  maxWidth: '113px',
                }}
              />
            </Box>
          );
        })}
      </Box>
    </Box>
  ) : null;
}
