import React from 'react';
import PropTypes from 'prop-types';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { Box, makeStyles } from '@material-ui/core';
import AmploChip from '../Controls/AmploChip';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'nowrap',
    overflow: 'scroll',
    '& > *': {
      margin: theme.spacing(0.5),
    },
    position: 'relative',
  },
}));

export default function QuickAdd({ groups, selected, onSelect }) {
  const classes = useStyles();

  const handleClick = (id) => () => {
    onSelect(id);
  };

  const handleDelete = () => () => {
    onSelect('');
  };

  return (
    <Box className={classes.root}>
      {groups.map((g) => {
        const groupSelected = selected === g._id;
        return (
          <AmploChip
            key={g._id}
            label={`#${g.displayGroupName}`}
            color={groupSelected ? 'primary' : 'default'}
            variant={groupSelected ? 'default' : 'outlined'}
            onClick={handleClick(g._id)}
            onDelete={groupSelected ? handleDelete(g._id) : null}
            deleteIcon={<CloseRoundedIcon />}
          />
        );
      })}
    </Box>
  );
}

QuickAdd.propTypes = {
  groups: PropTypes.array,
  selected: PropTypes.string,
  onSelect: PropTypes.func,
};
