import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import CameraAltOutlinedIcon from '@material-ui/icons/CameraAltOutlined';
import toBase64 from '../../utils/toBase64';
import UserAvatar from '../GroupView/UserAvatar';

const useStyles = makeStyles(() => ({
  button: {
    width: '100%',
  },
  profilePicture: {
    marginBottom: '24px',
  },
  profilePictureTitle: {
    marginBottom: '11px',
    marginTop: '16px',
  },
  userAvatar: {
    height: '55px',
    width: '55px',
    marginRight: '23px',
  },
}));

export default function SignupAbout({ onNext, formValues }) {
  const classes = useStyles();
  const [image, setImage] = useState('');
  const [imageFile, setImageFile] = useState(formValues.photo);
  const [newValues, setNewValues] = useState(formValues);
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (name) => (event) =>
    setNewValues({ ...newValues, [name]: event.target.value });

  function handleSubmit() {
    onNext({ ...newValues, photo: imageFile });
  }

  useEffect(() => {
    async function setImageSrc() {
      const dataUrl = await toBase64(imageFile);
      setImage(dataUrl);
    }
    if (imageFile) {
      setImageSrc();
    }
  }, [setImage, imageFile]);

  function handleFileChange(event) {
    const file = event.target.files[0];

    const validFileType = file.type.match(/^image\/.*$/);
    if (!validFileType) {
      enqueueSnackbar('You can only upload a valid image file!', {
        variant: 'error',
      });
      return false;
    }
    const IMAGE_SIZE_LIMIT = 12;
    const validSize = file.size / 1024 / 1024 < IMAGE_SIZE_LIMIT;
    if (!validSize) {
      enqueueSnackbar(`Image must smaller than ${IMAGE_SIZE_LIMIT}MB!`, {
        variant: 'error',
      });
      return false;
    }

    setImageFile(file);

    // Return false because we don't want to attempt to upload the file
    return false;
  }

  return (
    <Box>
      <TextField
        id="firstName"
        label="First Name"
        value={newValues.firstName || ''}
        onChange={handleChange('firstName')}
        required
        variant="outlined"
        margin="normal"
        fullWidth
      />
      <TextField
        id="lastName"
        label="Last Name"
        value={newValues.lastName || ''}
        onChange={handleChange('lastName')}
        required
        variant="outlined"
        margin="normal"
        fullWidth
      />
      <TextField
        id="email"
        label="Email Address"
        value={newValues.email || ''}
        onChange={handleChange('email')}
        required
        variant="outlined"
        margin="normal"
        type="email"
        fullWidth
      />
      <Typography variant="h5" className={classes.profilePictureTitle}>
        <Typography style={{ fontWeight: '500' }} component="span">
          Profile Picture
        </Typography>{' '}
        <Typography style={{ fontWeight: '400' }} component="span">
          (Optional)
        </Typography>
      </Typography>
      <Grid container alignItems="center" className={classes.profilePicture}>
        <Grid item>
          <UserAvatar user={{ photo: image }} className={classes.userAvatar} />
        </Grid>
        <Grid item xs>
          <Button
            variant="contained"
            color="secondary"
            component="label"
            disableElevation
            className={classes.button}
            startIcon={<CameraAltOutlinedIcon />}
            onChange={handleFileChange}
          >
            Upload
            <input type="file" hidden accept="image/" />
          </Button>
        </Grid>
      </Grid>
      <Button
        variant="contained"
        color="primary"
        disableElevation
        aria-label="next step"
        onClick={handleSubmit}
        disabled={
          !newValues.firstName || !newValues.lastName || !newValues.email
        }
        fullWidth
      >
        Next
      </Button>
    </Box>
  );
}

SignupAbout.propTypes = {
  onNext: PropTypes.func,
  formValues: PropTypes.object,
};
