import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputAdornment,
  makeStyles,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { useHistory } from 'react-router-dom';
import { ENV, PAGE_HEIGHT } from '../../constants';
import ValidityIndicator from '../Controls/ValidityIndicator';
import toBase64 from '../../utils/toBase64';
import UserAvatar from '../GroupView/UserAvatar';
import Header from '../Header';

const useStyles = makeStyles((theme) => ({
  root: {
    height: PAGE_HEIGHT,
    overflow: 'hidden',
  },
  main: {
    padding: '0 20px 10px 20px',
    backgroundColor: 'white',
    overflowY: 'scroll',
    flex: 1,
  },
  buttons: {
    marginTop: '20px',
  },
  subHeader: {
    color: theme.palette.gray.darker,
    marginBottom: '14px',
  },
  userAvatar: {
    height: '59px',
    width: '59px',
    marginRight: '23px',
  },
  bottomControls: {
    bottom: '0',
    left: 0,
    right: 0,
    backgroundColor: 'white',
    padding: '10px 20px',
    zIndex: '100',
  },
}));

const postUpdate = async (id, data) => {
  const response = await fetch(`${ENV.API_URL}/user/${id}/update`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });

  try {
    const result = await response.json();
    return result;
  } catch (err) {
    return false;
  }
};

export default function UserEdit({ user, loadUser }) {
  const id = user._id;
  const history = useHistory();
  const [validity, setValidity] = useState({ validity: '', message: '' });
  const [userHandle, setUserHandle] = useState(user.displayHandle);
  const [userIntro, setUserIntro] = useState(user.intro);
  const [image, setImage] = useState(user.photo);
  const [imageFile, setImageFile] = useState();
  const [about, setAbout] = useState({
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
  });
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (name) => (event) =>
    setAbout({ ...about, [name]: event.target.value });

  useEffect(() => {
    async function setImageSrc() {
      const dataUrl = await toBase64(imageFile);
      setImage(dataUrl);
    }
    if (imageFile) {
      setImageSrc();
    }
  }, [setImage, imageFile]);

  function handleFileChange(event) {
    const file = event.target.files[0];

    const validFileType = file.type.match(/^image\/.*$/);
    if (!validFileType) {
      enqueueSnackbar('You can only upload a valid image file!', {
        variant: 'error',
      });
      return false;
    }
    const IMAGE_SIZE_LIMIT = 12;
    const validSize = file.size / 1024 / 1024 < IMAGE_SIZE_LIMIT;
    if (!validSize) {
      enqueueSnackbar(`Image must smaller than ${IMAGE_SIZE_LIMIT}MB!`, {
        variant: 'error',
      });
      return false;
    }

    setImageFile(file);

    // Return false because we don't want to attempt to upload the file
    return false;
  }

  const [saving, setSaving] = useState(false);
  async function onSubmit(event) {
    event.preventDefault();
    setSaving(true);
    const values = {
      handle: userHandle,
      intro: userIntro,
      photo: image,
      ...about,
    };
    const result = await postUpdate(id, values);

    if (!result.success) {
      enqueueSnackbar(`Failed to save change, try again later`, {
        variant: 'error',
      });
      return;
    }

    enqueueSnackbar('Updated', { variant: 'success' });
    setSaving(false);
    loadUser();

    history.push(`/user/${id}/details`);
  }

  useEffect(() => {
    let canceled = false;
    const nameValidationTimeout = setTimeout(async () => {
      if (user.displayHandle === userHandle) {
        setValidity({
          validity: '',
          message: '',
        });
      } else {
        const body = { handle: userHandle };
        setValidity({ validity: '', message: '' });
        const isValid = await fetch(`${ENV.API_URL}/validateHandle`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        });

        const response = await isValid.json();

        if (!canceled) {
          setValidity({
            validity: response.validity,
            message: response.message,
          });
        }
      }
    }, 1000);

    return () => {
      clearTimeout(nameValidationTimeout);
      canceled = true;
    };
  }, [userHandle, user.displayHandle]);

  const aboutChanged =
    user.firstName !== about.firstName ||
    user.lastName !== about.lastName ||
    user.email !== about.email;

  const formValid =
    validity.validity === 'success' ||
    aboutChanged ||
    (validity.validity !== 'error' &&
      (userIntro !== user.intro || image !== user.photo));
  const nameValid = validity.validity === '' || validity.validity === 'success';

  return (
    <form autoComplete="off" onSubmit={onSubmit}>
      <Box className={classes.root} display="flex" flexDirection="column">
        <Header back title="Edit Profile" />
        <Box className={classes.main}>
          <Typography variant="h6" className={classes.subHeader}>
            Profile Picture
          </Typography>
          <Grid container alignItems="center">
            <Grid item>
              <UserAvatar
                user={{ ...user, photo: image }}
                className={classes.userAvatar}
              />
            </Grid>
            <Grid item xs>
              <Button
                variant="contained"
                component="label"
                disableElevation
                className={classes.button}
                endIcon={<EditIcon />}
                onChange={handleFileChange}
              >
                Edit
                <input type="file" hidden accept="image/" />
              </Button>
            </Grid>
          </Grid>
          <FormControl margin="normal" fullWidth>
            <TextField
              id="groupName"
              label="Username"
              value={userHandle}
              onChange={(event) => setUserHandle(event.target.value)}
              error={!nameValid}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">@</InputAdornment>
                ),
              }}
              variant="outlined"
              margin="normal"
            />
            <ValidityIndicator isValid={nameValid} text={validity.message} />
          </FormControl>
          <FormControl margin="normal" fullWidth>
            <TextField
              id="groupIntro"
              label="Intro Message"
              value={userIntro}
              onChange={(event) => setUserIntro(event.target.value)}
              placeholder="Hey! Thanks for following me."
              multiline
              rows={3}
              InputLabelProps={{ shrink: true }}
              fullWidth
              variant="outlined"
              margin="normal"
            />
          </FormControl>
          <TextField
            id="firstName"
            label="First Name"
            value={about.firstName || ''}
            onChange={handleChange('firstName')}
            required
            variant="outlined"
            margin="normal"
            fullWidth
          />
          <TextField
            id="lastName"
            label="Last Name"
            value={about.lastName || ''}
            onChange={handleChange('lastName')}
            required
            variant="outlined"
            margin="normal"
            fullWidth
          />
          <FormControl margin="normal" fullWidth>
            <TextField
              id="email"
              label="Email Address"
              value={about.email || ''}
              onChange={handleChange('email')}
              required
              variant="outlined"
              margin="normal"
              type="email"
              fullWidth
            />
          </FormControl>
        </Box>
        <Paper className={classes.bottomControls} elevation={3}>
          <Grid
            container
            display="flex"
            justifyContent="space-between"
            spacing={1}
          >
            <Grid item xs={6}>
              <Button
                variant="contained"
                disableElevation
                aria-label="cancel"
                onClick={() => history.goBack()}
                className={classes.button}
                fullWidth
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                color="primary"
                disableElevation
                aria-label="Save Changes"
                disabled={!formValid || saving}
                type="submit"
                className={classes.button}
                fullWidth
              >
                Save Changes
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </form>
  );
}

UserEdit.propTypes = {
  user: PropTypes.shape({
    _id: PropTypes.string,
    displayHandle: PropTypes.string,
    intro: PropTypes.string,
    photo: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
  }),
  loadUser: PropTypes.func,
};
