import React from 'react';
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom';
import { GroupEdit } from './GroupEdit';
import { GroupNotFound, GroupViewDetails } from './GroupView';
import GroupView from './GroupView/GroupView';
import useGroup from './GroupView/useGroup';
import PageLoader from './PageLoader';

export default function Group() {
  const { id } = useParams();
  const [group, setGroup] = useGroup(id);
  const match = useRouteMatch();

  if (group.error) {
    return <GroupNotFound />;
  }

  return group.loading ? (
    <PageLoader />
  ) : (
    <div>
      <Switch>
        <Route path={`${match.path}/details/edit`}>
          <GroupEdit group={group.data} setGroup={setGroup} />
        </Route>
        <Route path={`${match.path}/details`}>
          <GroupViewDetails group={group.data} onGroupUpdate={setGroup} />
        </Route>
        <Route path={`${match.path}/error`}>
          <GroupNotFound />
        </Route>
        <Route>
          <GroupView group={group.data} />
        </Route>
      </Switch>
    </div>
  );
}
