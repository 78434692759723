import React from 'react';
import PropTypes from 'prop-types';
import { Link, makeStyles, Typography, useTheme } from '@material-ui/core';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import clsx from 'clsx';
import { ENV } from '../../constants';

const useStyles = makeStyles((theme) => ({
  button: {
    alignItems: 'center',
    display: 'flex',
    height: '40px',
    justifyContent: 'center',
    textDecoration: 'none !important',
    width: '100%',
  },
  primary: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.main,
  },
  secondary: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
}));

export default function AddToCalendar({ eventId, type }) {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Link
      href={`${ENV.API_URL}/events/${eventId}/calendar`}
      className={clsx(
        classes.button,
        type === 'secondary' ? classes.secondary : classes.primary
      )}
    >
      <Typography
        variant="h4"
        style={{
          display: 'flex',
          alignItems: 'center',
          color: theme.palette[type || 'primary'].main,
        }}
      >
        Add to Calendar
        <CalendarTodayOutlinedIcon style={{ marginLeft: '10px' }} />
      </Typography>
    </Link>
  );
}

AddToCalendar.propTypes = {
  eventId: PropTypes.string,
  type: PropTypes.string,
};
