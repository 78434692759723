import React from 'react';
import PropTypes from 'prop-types';
import { Box, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import BackButton from './Controls/BackButton';

const useStyles = makeStyles(() => ({
  header: {
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'relative',
    padding: '20px',
  },
  shadow: {
    boxShadow: '0px 4px 6px -3px rgba(0, 0, 0, 0.2)',
  },
}));

export default function Header({ back, action, title, shadow }) {
  const classes = useStyles();
  let titleData = title;

  if (typeof title === 'string') {
    titleData = (
      <Typography color="primary" variant="h2">
        {title}
      </Typography>
    );
  }

  return (
    <Box className={clsx(classes.header, { [classes.shadow]: shadow })}>
      {back && <BackButton />}
      {(title || action) && (
        <Box display="flex" justifyContent="space-between" alignItems="center">
          {titleData}
          {action}
        </Box>
      )}
    </Box>
  );
}

Header.propTypes = {
  action: PropTypes.node,
  back: PropTypes.bool,
  title: PropTypes.any,
  shadow: PropTypes.bool,
};
