import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, useTheme } from '@material-ui/core';

export default function CopyLinkButton({
  beforeText,
  afterText,
  url,
  ...props
}) {
  const theme = useTheme();

  const [linkCopied, setLinkCopied] = useState(false);

  useEffect(() => {
    let mounted = true;
    if (linkCopied) {
      setTimeout(() => {
        if (mounted) setLinkCopied(false);
      }, 3000);
    }

    return () => {
      mounted = false;
    };
  }, [linkCopied]);

  function copyLink() {
    if (navigator.clipboard) {
      // This is async, but we'll setLinkCopied ASAP to prevent unmount errors
      // We can't use this in a useEffect because it must be called from a user interaction (e.g. click event)
      navigator.clipboard.writeText(url);
    } else {
      const copyText = document.querySelector('#copy-text-input');

      // Copying to clipboard requires a text input
      copyText.type = 'text';
      copyText.select();
      document.execCommand('copy');

      // Re-hide input
      copyText.type = 'hidden';
    }

    setLinkCopied(true);
  }

  let style = linkCopied
    ? {
        border: `2px solid ${theme.palette.secondary.main}`,
      }
    : {};

  style = { ...style, ...props.style };

  return !linkCopied ? (
    <Button
      variant="contained"
      color="primary"
      key="uncopied"
      disableElevation
      onClick={copyLink}
      style={style}
      {...props}
    >
      <input type="hidden" value={url} id="copy-text-input" />
      {beforeText}
    </Button>
  ) : (
    <Button
      disableElevation
      color="default"
      variant="contained"
      key="copied"
      style={style}
      {...props}
    >
      {afterText}
    </Button>
  );
}

CopyLinkButton.propTypes = {
  beforeText: PropTypes.string,
  afterText: PropTypes.string,
  url: PropTypes.string,
  style: PropTypes.object,
};
