export default function displayHandle(
  user,
  {
    fields = ['displayHandle', 'handle', 'firstName', 'lastName'],
    backupLabel = 'Profile',
  } = {}
) {
  if (!user) {
    return '';
  }

  const field = fields.find((key) => user[key]);
  return field ? user[field] : backupLabel;
}
