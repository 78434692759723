import React from 'react';
import { Chip, withStyles } from '@material-ui/core';

const AmploChip = withStyles((theme) => ({
  root: {
    borderRadius: '0',
    borderColor: theme.palette.primary.main,
    borderWidth: '2px',
  },
}))(({ classes, ...props }) => {
  return (
    <Chip
      classes={{
        root: classes.root,
      }}
      {...props}
    />
  );
});

export default AmploChip;
