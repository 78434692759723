import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { useCurrentUser } from '../context/UserContext';
import PageLoader from './PageLoader';
import UserEdit from './UserEdit/UserEdit';
import UserView from './UserView/UserView';
import UserViewDetails from './UserView/UserViewDetails';

export default function User() {
  const { loading, user, loadUser, setUser } = useCurrentUser();
  const match = useRouteMatch();

  return loading ? (
    <PageLoader />
  ) : (
    <Switch>
      <Route path={`${match.path}/details/edit`}>
        <UserEdit user={user} loadUser={loadUser} />
      </Route>
      <Route path={`${match.path}/details`}>
        <UserViewDetails user={user} onUserChange={setUser} />
      </Route>
      <Route>
        <UserView user={user} />
      </Route>
    </Switch>
  );
}
