import React from 'react';
import PropTypes from 'prop-types';
import UserIcon from '../Icons/UserIcon';
import CreateSuccess from '../CreateSuccess/CreateSuccess';
import GuideCard from '../CreateSuccess/GuideCard';
import MessageIcon from '../Icons/MessageIcon';
import { ENV } from '../../constants';

export default function GroupCreateSuccess({ groupName = 'MyCoolGroup' }) {
  return (
    <CreateSuccess
      name={`#${groupName}`}
      message="Your group is all set up!"
      cta="to receive texts in the group."
      quickGuide="Groups"
    >
      <GuideCard
        body={
          <>
            To send a message to your group followers:{' '}
            <b>
              text #{groupName} + your message to {ENV.SHORT_CODE}
            </b>
          </>
        }
        icons={
          <>
            <UserIcon multi style={{ fontSize: 42 }} />
            <MessageIcon style={{ fontSize: 52 }} />
          </>
        }
      />
      <GuideCard
        body={
          <>
            People can follow the group by texting{' '}
            <b>
              #{groupName} to {ENV.SHORT_CODE}
            </b>
          </>
        }
        icons={
          <>
            <MessageIcon style={{ fontSize: 52 }} />
            <UserIcon check style={{ fontSize: 42 }} />
          </>
        }
      />
    </CreateSuccess>
  );
}

GroupCreateSuccess.propTypes = {
  groupName: PropTypes.string,
};
