import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Typography, makeStyles } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

const useStyles = makeStyles(() => ({
  back: {
    alignItems: 'center',
    display: 'flex',
    marginBottom: '10px',
  },
}));

export default function BackButton({ onClick }) {
  const classes = useStyles();
  const history = useHistory();
  return (
    <Typography
      color="primary"
      className={classes.back}
      variant="h4"
      onClick={() => onClick(history)}
    >
      <ArrowBackIosIcon color="primary" />
      Back
    </Typography>
  );
}

BackButton.defaultProps = {
  onClick: (history) => history.goBack(),
};

BackButton.propTypes = {
  onClick: PropTypes.func,
  gutterTop: PropTypes.bool,
};
