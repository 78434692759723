import React from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import { PAGE_HEIGHT } from '../constants';

export default function PageLoader() {
  return (
    <Box
      display="flex"
      justifyContent="center"
      style={{ height: PAGE_HEIGHT, paddingTop: '200px' }}
    >
      <CircularProgress />
    </Box>
  );
}
