import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Avatar, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const colors = ['0060AF', '92278F', '20BDBE'];

/**
 * Pick a color based on the first character of the name.
 * With this we can get a seemingly random color from the array,
 * but which is always the same for the same user.
 * @param {string} name
 */
const getColorFromName = (name = 'a') =>
  colors[name.charCodeAt(0) % colors.length];

const useStyles = makeStyles((theme) => ({
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

export default function UserAvatar(props) {
  const { user, large, small, style, ...rest } = props;
  const classes = useStyles();
  const [imgStyle, setImgStyle] = useState({
    height: '100%',
    width: '100%',
  });

  function imgOnLoad(e) {
    const tall = e.target.naturalHeight >= e.target.naturalWidth;
    const height = tall ? 'auto' : '100%';
    const width = !tall ? 'auto' : '100%';
    setImgStyle({ height, width });
  }

  const styles = style || {};
  if (!user.photo)
    styles.backgroundColor = `#${getColorFromName(user.firstName)}`;

  return (
    <Avatar
      {...rest}
      style={styles}
      src={user.photo}
      alt={`${user.firstName} ${user.lastName}`}
      className={clsx(
        props.className,
        classes.root,
        small && classes.small,
        large && classes.large
      )}
      imgProps={{ onLoad: imgOnLoad, style: imgStyle }}
    ></Avatar>
  );
}

UserAvatar.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    photo: PropTypes.string,
  }),
  large: PropTypes.bool,
  small: PropTypes.bool,
  style: PropTypes.any,
  className: PropTypes.any,
};
