import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import {
  Box,
  Divider,
  List,
  makeStyles,
  Typography,
  useTheme,
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import useEvent from '../../utils/useEvent';
import Header from '../Header';
import useUser from '../../utils/useUser';
import UserAvatar from '../GroupView/UserAvatar';

const useStyles = makeStyles({
  main: {
    backgroundColor: 'white',
    padding: '0 20px',
  },
});

function UserListItem({ userId }) {
  const theme = useTheme();
  const { data, isLoading } = useUser(userId);

  if (!data || isLoading) {
    return (
      <Box display="flex" alignItems="center" style={{ height: '64px' }}>
        <Skeleton variant="circle" />
        <Skeleton variant="text" />
      </Box>
    );
  }

  return (
    <Box display="flex" alignItems="center" style={{ height: '64px' }}>
      <UserAvatar user={data} style={{ margin: '0 10px' }} />
      <Typography variant="body1" style={{ color: theme.palette.gray.darker }}>
        {data.firstName} {data.lastName}
      </Typography>
    </Box>
  );
}

UserListItem.propTypes = {
  userId: PropTypes.string,
};

export default function EventAttendees() {
  const classes = useStyles();
  const { id } = useParams();
  const { data: event, isLoading: eventLoading } = useEvent(id);

  if (eventLoading) {
    return <Skeleton />;
  }

  if (!event) {
    return null;
  }

  // TODO - we may want to grab the possible statuses from the API
  // Instead of hardcoding
  const attendees = event.users.filter((u) => u.status === 'YES');
  const maybes = event.users.filter((u) => u.status === 'MAYBE');
  const nos /* vroom vroom */ = event.users.filter((u) => u.status === 'NO');

  return event ? (
    <Box>
      <Header back title="RSVP List" />
      <Box className={classes.main}>
        {attendees.length > 0 ? (
          <>
            <Typography color="primary" variant="h3">
              {attendees.length === 1
                ? '1 Person'
                : `${attendees.length} People`}{' '}
              Attending
            </Typography>
            <List>
              {attendees.map((user) => (
                <>
                  <UserListItem userId={user.id} />
                  <Divider />
                </>
              ))}
            </List>
          </>
        ) : null}
        {maybes.length > 0 ? (
          <>
            <Typography color="primary" variant="h3">
              {maybes.length === 1 ? '1 Maybe' : `${maybes.length} Maybes`}
            </Typography>
            <List>
              {maybes.map((user) => (
                <>
                  <UserListItem userId={user.id} />
                  <Divider />
                </>
              ))}
            </List>
          </>
        ) : null}
        {nos.length > 0 ? (
          <>
            <Typography color="primary" variant="h3">
              {nos.length === 1 ? '1 No' : `${nos.length} Nos`}
            </Typography>
            <List>
              {nos.map((user) => (
                <>
                  <UserListItem userId={user.id} />
                  <Divider />
                </>
              ))}
            </List>
          </>
        ) : null}
      </Box>
    </Box>
  ) : (
    <Box></Box>
  );
}
