import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import SendOutlinedIcon from '@material-ui/icons/SendOutlined';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { ENV, PAGE_HEIGHT } from '../../constants';
import sent from '../../img/sent.svg';
import QuickAdd from './QuickAdd';
import useGroupList from '../../utils/useGroupList';
import { useCurrentUser } from '../../context/UserContext';
import { UpcomingEventsSelector } from '../Events';

const MAX_LENGTH = 1600;

const useStyles = makeStyles(() => ({
  form: {
    paddingBottom: '20px',
  },
  button: {
    width: '100%',
    marginTop: '20px',
  },
  success: {
    position: 'fixed',
    bottom: '-300px',
    transition: 'bottom 0.5s cubic-bezier(0.85, 0, 0.15, 1)',
    left: 0,
    right: 0,
    minHeight: '215px',
    backgroundColor: '#2b2f51',
    color: 'white',
    padding: '35px',
    paddingBottom: '60px', // compensating for the view area not being exactly the height of the window
    zIndex: '100',
    textAlign: 'center',
  },
  successText: {
    color: 'white',
  },
  sentImage: {
    marginBottom: '20px',
    marginTop: '15px',
  },
  closeIcon: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    height: '40px',
    width: '40px',
  },
  quickAdd: {
    marginBottom: '8px',
  },
  sendNotice: {
    marginTop: '11px',
    fontSize: '14px',
    lineHeight: '17px',
  },
}));

const requestSend = async (messageBody) => {
  const response = await fetch(`${ENV.API_URL}/sendMessage`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ message: messageBody, sentFromWeb: true }),
  });

  try {
    const result = await response.json();
    return result;
  } catch (err) {
    return false;
  }
};

export default function SendMessage() {
  const { user } = useCurrentUser();
  const { loading, error, data } = useGroupList(user?._id);
  const [saving, setSaving] = useState(false);
  const [messageText, setMessageText] = useState('');
  const [selectedGroup, setSelectedGroup] = useState('');
  const [temporarilyDisableSubmit, setTemporarilyDisableSubmit] = useState(
    false
  );
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  // If temporarilyDisableSubmit is set to true,
  // Start a timer that sets it to false after a timeout
  // Currently using this to prevent user from spamming submit
  useEffect(() => {
    let mounted = true;

    setTimeout(() => {
      if (mounted) {
        setTemporarilyDisableSubmit(false);
      }
    }, [2000]);

    return () => {
      mounted = false;
    };
  }, [temporarilyDisableSubmit]);

  const targetingGroupOrUser = /^(#|@)\S+\s*/.test(messageText);

  function handleMessageChange(event) {
    setMessageText(event.target.value);
  }

  function handleBlur() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  function handleGroupSelect(id) {
    setSelectedGroup(id);
    const groupName = data.find((g) => g._id === id)?.displayGroupName;
    const groupNameText = groupName ? `#${groupName} ` : '';

    const messageTextWithoutGroup = messageText.replace(/^#\S+\s*/, '');

    setMessageText(`${groupNameText}${messageTextWithoutGroup}`);
  }

  const [successInfo, setSuccessInfo] = useState('');
  const [doShowSuccess, setDoShowSuccess] = useState(false);
  const [successTimeout, setSuccessTimeout] = useState();
  function showSuccess() {
    setDoShowSuccess(true);
    setSuccessTimeout(setTimeout(() => setDoShowSuccess(false), 4000));
  }

  function hideSuccess() {
    setDoShowSuccess(false);
    clearTimeout(successTimeout);
  }

  function onEditClick() {
    history.push(`/signup`);
  }

  async function onSubmit(event) {
    event.preventDefault();

    if (messageText.length > MAX_LENGTH) return;

    setSaving(true);

    const result = await requestSend(messageText);

    const sendCommands = ['DIRECT_MESSAGE', 'SEND', 'GROUP_SEND'];

    if (result.success) {
      if (sendCommands.includes(result.command)) {
        const recipient = result.data.groupName
          ? `#${result.data.groupName}`
          : `@${result.data.userHandle}`;
        const followers =
          result.command !== 'DIRECT_MESSAGE' ? 'the followers of ' : '';
        const successMessage = `Your message was sent to ${followers}${recipient}`;

        setSuccessInfo(successMessage);
        showSuccess();
      } else {
        result.responses.forEach((response) =>
          enqueueSnackbar(response, { variant: 'info' })
        );
      }
      setMessageText('');
      setSelectedGroup('');
    } else {
      let errorMessage = 'Failed to send message. Please try again later.';
      if (result.command === 'GROUP_SEND') {
        switch (result.resultCode) {
          case 'INVALID_GROUP':
            errorMessage = `A group named #${result.data.groupName} does not exist. Please check the spelling and try again.`;
            break;
          case 'NOT_A_MEMBER':
            errorMessage = `You are not following #${result.data.groupName}`;
            break;
          case 'REPLY_NOT_ALLOWED':
            errorMessage = `You are not allowed to reply in #${result.data.groupName}`;
            break;
          default:
            break;
        }

        enqueueSnackbar(errorMessage, { variant: 'error' });
      } else if (result.command === 'DIRECT_MESSAGE') {
        let action;
        switch (result.resultCode) {
          case 'INVALID_TARGET':
            errorMessage = `@${result.data.userHandle} does not exist. Please check the spelling and try again.`;
            break;
          case 'NO_HANDLE':
            errorMessage = `To send messages directly to someone, add a username to your profile.`;
            action = function signUpAction(key) {
              return (
                <Button
                  size="small"
                  variant="text"
                  style={{ color: 'white', width: 'auto' }}
                  onClick={() => {
                    closeSnackbar(key);
                    onEditClick();
                  }}
                >
                  Edit Profile
                </Button>
              );
            };

            break;
          case 'NOT_ALLOWED':
            errorMessage = `Sorry, you are not allowed to send messages to this user.`;
            break;
          default:
            break;
        }

        enqueueSnackbar(errorMessage, { variant: 'error', action });
      } else {
        result.responses.forEach((response) =>
          enqueueSnackbar(response, { variant: 'error' })
        );
      }

      setTemporarilyDisableSubmit(true);
    }

    setSaving(false);
  }

  const remaining = MAX_LENGTH - messageText.length;
  const overLength = remaining < 0;
  const formValid = messageText.length > 0 && !overLength;
  const remainingText = `${remaining} character${
    remaining !== 1 ? 's' : ''
  } remaining`;

  const followerCount = user && user.followers ? user.followers.length : 0;
  const followers = `follower${followerCount !== 1 ? 's' : ''}`;
  const sendNotice = user
    ? `This message will be sent to the ${followerCount} ${followers} of @${user.displayHandle}.`
    : '';

  return (
    <div
      style={{
        minHeight: PAGE_HEIGHT,
        padding: '20px 22px',
        backgroundColor: 'white',
      }}
    >
      <Typography variant="h2">Send a Message</Typography>
      <form className={classes.form} autoComplete="off" onSubmit={onSubmit}>
        <FormControl margin="normal" fullWidth>
          <TextField
            id="message"
            label="Message"
            value={messageText}
            onChange={handleMessageChange}
            onBlur={handleBlur}
            placeholder="Type your message"
            multiline
            rows={4}
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            margin="normal"
            aria-describedby="intro-helper-text"
          />
          <FormHelperText id="intro-helper-text" error={overLength}>
            {remainingText}
          </FormHelperText>
        </FormControl>
        {!loading && !error && !!data.length && (
          <>
            <Typography variant="h4" className={classes.quickAdd}>
              Quick Add
            </Typography>
            <QuickAdd
              groups={data}
              selected={selectedGroup}
              onSelect={handleGroupSelect}
            />
          </>
        )}
        <Button
          key={!formValid || saving || temporarilyDisableSubmit}
          variant="contained"
          color="primary"
          className={classes.button}
          disableElevation
          aria-label="Send Message"
          disabled={!formValid || saving || temporarilyDisableSubmit}
          type="submit"
          endIcon={<SendOutlinedIcon />}
        >
          Send Message
        </Button>
        {!targetingGroupOrUser && messageText.length > 0 && user.handle && (
          <FormHelperText className={classes.sendNotice}>
            {sendNotice}
          </FormHelperText>
        )}
      </form>
      <UpcomingEventsSelector />
      <Box
        className={classes.success}
        style={{ bottom: doShowSuccess ? '0' : '' }}
      >
        <CloseRoundedIcon className={classes.closeIcon} onClick={hideSuccess} />
        <Grid
          style={{ height: '100%' }}
          container
          direction="column"
          justify="flex-end"
          alignItems="center"
        >
          <Grid item>
            <img src={sent} alt="sent" className={classes.sentImage} />
          </Grid>
          <Grid item>
            <Typography variant="h3" className={classes.successText}>
              {successInfo}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

SendMessage.propTypes = {};
