import { SvgIcon } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as UserCheck } from '../../img/user-check.svg';
import { ReactComponent as User } from '../../img/user.svg';
import { ReactComponent as Users } from '../../img/users.svg';

export default function UserIcon(props) {
  const { multi, check, style, ...rest } = props;

  let icon = User;
  let box = '0 0 42 42';
  if (multi) {
    icon = Users;
    box = '0 0 41 42';
  } else if (check) {
    icon = UserCheck;
  }

  return (
    <SvgIcon
      {...rest}
      component={icon}
      viewBox={box}
      style={{ color: 'rgba(0,0,0,0)', ...style }}
    />
  );
}

UserIcon.propTypes = {
  style: PropTypes.any,
  multi: PropTypes.bool,
  check: PropTypes.bool,
};
