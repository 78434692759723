import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Skeleton } from '@material-ui/lab';
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import useFollowers from '../../utils/useFollowers';
import FollowerList from '../FollowerList/FollowerList';
import { ENV } from '../../constants';
import Header from '../Header';
import EditButton from '../Controls/EditButton';
import UserAvatar from '../GroupView/UserAvatar';

const useStyles = makeStyles({
  user: {
    marginTop: '25px',
    marginBottom: '39px',
  },
  userHandle: {
    letterSpacing: 'initial',
    textTransform: 'initial',
    height: '30px',
  },
  userName: {
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '20px',
  },
  userAvatar: {
    height: '46px',
    width: '46px',
    marginRight: '15px',
  },
  editButton: {
    margin: '-6px -8px',
  },
});

export default function UserViewDetails({ user, onUserChange }) {
  const { _id: id } = user;
  const [defaultReply, setDefaultReply] = useState(!!user.defaultAllowReply);
  const [
    { loading, followers, unregisteredFollowers },
    setFollowers,
  ] = useFollowers(id, 'user');
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const post = async (endpoint, data) => {
    const response = await fetch(`${ENV.API_URL}/user/${endpoint}`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    try {
      const result = await response.json();
      return result;
    } catch (err) {
      return false;
    }
  };

  const postAllowReply = async (userId, followerId, allowReply) => {
    return post('setAllowReply', { userId, followerId, allowReply });
  };

  const postDefaultAllowReply = async (userId, allowReply) => {
    return post('setDefaultAllowReply', { userId, allowReply });
  };

  const [saving, setSaving] = useState(false);
  const onAllowReplyToggleClick = async (userId, allowReply) => {
    if (saving) return;

    setSaving(true);
    // Toggle user's local value for intant feedback
    setFollowers((prevFollowers) =>
      prevFollowers.map((f) => (f._id === userId ? { ...f, allowReply } : f))
    );

    const result = await postAllowReply(id, userId, allowReply);

    if (!result.success) {
      // undo change and display error message
      setFollowers((prevFollowers) =>
        prevFollowers.map((f) =>
          f._id === userId ? { ...f, allowReply: !allowReply } : f
        )
      );

      enqueueSnackbar('Failed to save change', { variant: 'error' });
    } else {
      const feedback = allowReply ? 'enabled' : 'disabled';

      enqueueSnackbar(`User reply ${feedback}`, { variant: 'success' });
    }

    setSaving(false);
  };

  const [savingDefault, setSavingDefault] = useState(false);
  const onDefaultAllowReplyToggleClick = async (allowReply) => {
    if (savingDefault) return;

    setSavingDefault(true);
    // Toggle local value for intant feedback
    setDefaultReply(allowReply);

    const result = await postDefaultAllowReply(id, allowReply);

    if (!result.success) {
      // undo change and display error message
      setDefaultReply(!allowReply);

      enqueueSnackbar('Failed to save change', { variant: 'error' });
    } else {
      enqueueSnackbar(`Settings updated`, { variant: 'success' });
      onUserChange({ ...user, defaultAllowReply: allowReply });
    }

    setSavingDefault(false);
  };

  return (
    <Box>
      <Header
        back
        title={
          <Grid container>
            <Grid item>
              <UserAvatar className={classes.userAvatar} user={user} />
            </Grid>
            <Grid item>
              <Typography
                color="primary"
                variant="subtitle1"
                className={classes.userHandle}
              >
                {user.displayHandle ? `@${user.displayHandle}` : ' '}
              </Typography>
              <Typography
                color="primary"
                variant="body1"
                className={classes.userName}
              >
                {user.firstName} {user.lastName}
              </Typography>
            </Grid>
          </Grid>
        }
        action={
          <EditButton
            onClick={(history) =>
              history.push(`${history.location.pathname}/edit`)
            }
          />
        }
      />
      <Box>
        {loading ? (
          <Skeleton />
        ) : (
          <FollowerList
            defaultSettingTip="Allow new followers to reply directly to you by using your @username."
            defaultAllowReply={defaultReply}
            followers={followers}
            unregisteredFollowers={unregisteredFollowers}
            onDefaultAllowReplyChange={onDefaultAllowReplyToggleClick}
            savingDefault={savingDefault}
            onFollowerAllowReplyChange={onAllowReplyToggleClick}
            savingFollower={saving}
          />
        )}
      </Box>
    </Box>
  );
}

UserViewDetails.propTypes = {
  user: PropTypes.shape({
    _id: PropTypes.string,
    defaultAllowReply: PropTypes.bool,
    displayHandle: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    photo: PropTypes.string,
  }),
  onUserChange: PropTypes.func,
};
