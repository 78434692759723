import { useSnackbar } from 'notistack';
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  makeStyles,
  TextField,
  Typography,
  useTheme,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { ENV, PAGE_HEIGHT } from '../../constants';
import useIsMount from '../../utils/useIsMount';
import ValidityIndicator from '../Controls/ValidityIndicator';
import GroupCreateSuccess from './GroupCreateSuccess';
import { useCurrentUser } from '../../context/UserContext';
import PageLoader from '../PageLoader';

const requestCreate = async (name, intro) => {
  const response = await fetch(`${ENV.API_URL}/group/create`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ name, intro }),
  });

  try {
    const result = await response.json();
    return result;
  } catch (err) {
    return false;
  }
};

const useStyles = makeStyles(() => ({
  button: {
    display: 'block',
    width: '100%',
    marginTop: '20px',
  },
}));

export default function GroupCreate() {
  const [complete, setComplete] = useState(false);
  const [validity, setValidity] = useState({ validity: '', message: '' });
  const [groupName, setGroupName] = useState('');
  const [groupIntro, setGroupIntro] = useState('');
  const classes = useStyles();
  const theme = useTheme();
  const { user, loading } = useCurrentUser();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const intro = groupName
    ? groupIntro || `Thanks for joining #${groupName}.`
    : 'Thanks for joining my group.';

  const [saving, setSaving] = useState(false);
  async function onSubmit(event) {
    event.preventDefault();

    setSaving(true);

    const result = await requestCreate(groupName, intro);

    if (!result.success) {
      enqueueSnackbar(`Failed to create Group, ${result.message}`, {
        variant: 'error',
      });
      setSaving(false);
      return;
    }

    setSaving(false);
    setComplete(true);
  }

  const isMount = useIsMount();
  useEffect(() => {
    let canceled = false;
    const nameValidationTimeout = setTimeout(async () => {
      if (!isMount && !groupName) {
        setValidity({ validity: 'error', message: 'Please input a name' });
        return;
      }

      if (groupName === '') {
        setValidity({
          validity: '',
          message: '',
        });
      } else {
        const body = { groupName };
        setValidity({ validity: '', message: '' });
        const isValid = await fetch(`${ENV.API_URL}/group/validateGroupName`, {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        });

        const response = await isValid.json();

        if (!canceled) {
          setValidity({
            validity: response.validity,
            message: response.message,
          });
        }
      }
    }, 1000);

    return () => {
      clearTimeout(nameValidationTimeout);
      canceled = true;
    };
  }, [groupName, isMount]);

  function onEditClick() {
    history.push(`/user/${user._id}/details/edit`);
  }

  const formValid = validity.validity === 'success';
  const nameValid = validity.validity === '' || validity.validity === 'success';

  if (!loading && !user.allowCreateGroup) return null;

  if (complete) {
    return <GroupCreateSuccess groupName={groupName} />;
  }

  return (
    <div style={{ minHeight: PAGE_HEIGHT, padding: '20px 22px' }}>
      <Typography variant="h2">Create a Group</Typography>

      {loading && <PageLoader />}

      {!loading && !user.handle && (
        <Grid
          container
          style={{
            textAlign: 'center',
            minHeight: `calc(${PAGE_HEIGHT} - 80px)`,
          }}
          direction="column"
          justify="center"
          alignItems="center"
        >
          <Grid item>
            <Typography
              variant="h3"
              style={{ marginBottom: '24px', color: theme.palette.gray.dark }}
            >
              To create a new group, add a username to your profile.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              disableElevation
              aria-label="Edit my Profile"
              fullWidth
              onClick={onEditClick}
            >
              Edit my Profile
            </Button>
          </Grid>
        </Grid>
      )}

      {!loading && user.handle && (
        <form autoComplete="off" onSubmit={onSubmit}>
          <FormControl margin="normal">
            <TextField
              id="groupName"
              label="Group Name"
              value={groupName}
              onChange={(event) => setGroupName(event.target.value)}
              error={!nameValid}
              required
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">#</InputAdornment>
                ),
              }}
              variant="outlined"
              margin="normal"
              aria-describedby="name-helper-text"
            />
            <ValidityIndicator isValid={nameValid} text={validity.message} />
            <FormHelperText id="name-helper-text">
              This group name should let the users know what this group is
              about.
            </FormHelperText>
          </FormControl>
          <FormControl margin="normal">
            <TextField
              id="groupIntro"
              label="Intro Message"
              value={groupIntro}
              onChange={(event) => setGroupIntro(event.target.value)}
              placeholder={intro}
              multiline
              rows={3}
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              margin="normal"
              aria-describedby="intro-helper-text"
            />
            <FormHelperText id="intro-helper-text">
              This message will be sent to anyone that follows your group.
            </FormHelperText>
          </FormControl>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            disableElevation
            aria-label="create new Group"
            disabled={!formValid || saving}
            type="submit"
          >
            Create New Group
          </Button>
        </form>
      )}
    </div>
  );
}

GroupCreate.propTypes = {
  group: PropTypes.shape({
    _id: PropTypes.string,
    groupName: PropTypes.string,
    displayGroupName: PropTypes.string,
    intro: PropTypes.string,
    followers: PropTypes.arrayOf(PropTypes.object),
    defaultAllowReply: PropTypes.bool,
  }),
  setGroup: PropTypes.func,
};
