import { useEffect, useState } from 'react';
import { ENV } from '../constants';
import { authenticatedGet } from './authenticatedFetch';

const divideFollowers = (followers) => {
  const registered = followers.filter((f) => f.handle);
  const unregistered = followers.filter((f) => !f.handle);
  return [registered, unregistered];
};

/**
 * @param {string} id Id of either the group or user
 * @param {'group'|'user'} type Specify 'user' or 'group' to get that item's followers
 */
function useFollowers(id, type = 'group') {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [followers, setFollowers] = useState([]);
  const [unregisteredFollowers, setUnregisteredFollowers] = useState([]);

  if (type !== 'group' && type !== 'user')
    throw new Error(`Invalid type "${type}" in useFollowers`);

  useEffect(() => {
    const controller = new AbortController();

    if (id) {
      setLoading(true);

      (async () => {
        try {
          const response = await authenticatedGet(
            `${ENV.API_URL}/${type}/${id}/followers`,
            {
              signal: controller.signal,
            }
          );

          const followersResponse = await response.json();
          if (!followersResponse.followers) {
            throw new Error();
          }
          const [registered, unregistered] = divideFollowers(
            followersResponse.followers
          );

          setFollowers(registered);
          setUnregisteredFollowers(unregistered);
          setLoading(false);
        } catch (err) {
          console.error(err);
          if (err.name !== 'AbortError') {
            setError(true);
            setLoading(false);
          }
        }
      })();
    }

    return () => {
      setLoading(false);
      controller.abort();
    };
  }, [id, type, setLoading, setError]);

  const setFollowersCallback = (f = null) => {
    if (typeof f === 'function') {
      return setFollowers(f(followers));
    }

    return setFollowers(f);
  };

  return [
    { error, loading, followers, unregisteredFollowers },
    setFollowersCallback,
  ];
}

export default useFollowers;
