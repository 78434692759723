import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  TextField,
} from '@material-ui/core';
import { ENV } from '../../constants';
import { post } from '../../utils/fetch';
import ValidityIndicator from '../Controls/ValidityIndicator';
import BackButton from '../Controls/BackButton';

export default function SignupHandle({ onNext, onPrevious, formValues }) {
  const [validity, setValidity] = useState({ validity: '', message: '' });

  // Suggest user's first + last if they haven't entered a handle yet
  const initialValues = {
    ...formValues,
    handle:
      formValues.handle ||
      (formValues.firstName + formValues.lastName).toLowerCase(),
  };

  const [handle, setHandle] = useState(initialValues.handle || '');

  function handleSubmit() {
    onNext({ ...formValues, handle });
  }

  useEffect(() => {
    const controller = new AbortController();
    const handleValidationTimeout = setTimeout(async () => {
      // Ignore empty string
      const body = { handle };
      try {
        const isValid = await post(`${ENV.API_URL}/validateHandle`, body, {
          signal: controller.signal,
        });
        const response = await isValid.json();

        if (response.handle === handle) {
          setValidity({
            validity: response.validity,
            message: response.message,
          });
        }
      } catch (err) {
        console.error(err);
      }
    }, 1000);

    return () => {
      clearTimeout(handleValidationTimeout);
      controller.abort();
    };
  }, [handle]);

  const validating = validity.validity === 'validating';
  const formValid = validity.validity !== 'error';

  return (
    <Box>
      <FormControl>
        <TextField
          id="handle"
          label="Username"
          value={handle}
          onChange={(event) => {
            setValidity({
              validity: 'validating',
              message: 'Handle is being validated.',
            });
            setHandle(event.target.value);
          }}
          error={Boolean(handle) && !formValid}
          required
          InputProps={{
            startAdornment: <InputAdornment position="start">@</InputAdornment>,
          }}
          variant="outlined"
          margin="normal"
          aria-describedby="name-helper-text"
        />
        <ValidityIndicator
          isValid={validating ? undefined : formValid}
          text={validity.message}
        />
        <FormHelperText id="name-helper-text">
          This is the username we are suggesting so your followers can know your
          name. Feel free to edit it if you want something different.
        </FormHelperText>
      </FormControl>
      <Grid container alignItems="center" style={{ marginTop: '50px' }}>
        <Grid item xs={6} style={{ marginTop: '10px' }}>
          <BackButton fullWidth onClick={onPrevious} />
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            aria-label="next step"
            onClick={handleSubmit}
            fullWidth
            disabled={validating || !formValid}
          >
            Next
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

SignupHandle.propTypes = {
  onNext: PropTypes.func,
  onPrevious: PropTypes.func,
  formValues: PropTypes.object,
};
