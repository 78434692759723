import { useQuery } from 'react-query';
import { useSnackbar } from 'notistack';
import { ENV } from '../constants';
import { authenticatedGet } from './authenticatedFetch';

export default function useEvents(options = {}, skip) {
  const { enqueueSnackbar } = useSnackbar();

  let queryName = 'events';
  const queryParts = [];
  Object.keys(options).forEach((key) => {
    queryParts.push(`${key}=${options[key]}`);
  });
  if (queryParts.length) {
    queryName += `?${queryParts.join('&')}`;
  }

  return useQuery(
    queryName,
    async () => {
      const response = await authenticatedGet(`${ENV.API_URL}/${queryName}`);
      if (!response.ok) {
        throw new Error('Failed to fetch events');
      }

      const result = await response.json();

      return result.data;
    },
    {
      enabled: !skip,
      onError(response) {
        enqueueSnackbar(response.message, { variant: 'error' });
      },
    }
  );
}
