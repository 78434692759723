import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  makeStyles,
  OutlinedInput,
  Snackbar,
  Typography,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import {
  BOTTOM_BAR_OFFSET,
  ENV,
  PAGE_HEIGHT,
  SNACKBAR_DURATION,
} from '../constants';

import { post } from '../utils/fetch';
import PageLoader from './PageLoader';
import { authenticatedPost } from '../utils/authenticatedFetch';
import { useCurrentUser } from '../context/UserContext';

const useStyles = makeStyles({
  main: {
    minHeight: PAGE_HEIGHT,
    padding: `20px 22px ${BOTTOM_BAR_OFFSET}`,
  },
  textInput: {
    margin: '20px 0',
  },
});

export function MagicLink() {
  const { code } = useParams();
  const { loadUser } = useCurrentUser();
  const history = useHistory();
  const [token, setToken] = useState('');
  const [url, setUrl] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  useQuery(
    'getMagicLink',
    async () => {
      return post(`${ENV.API_URL}/urls/magic/${code}`);
    },
    {
      onSuccess: async (response) => {
        if (response.status === 500) {
          enqueueSnackbar('Failed to find magic URL.', { variant: 'error' });
          history.push('/');
        }

        const result = await response.json();
        if (result.data?.token) {
          setToken(result.data?.token);
          setUrl(result.data?.url);
        } else if (result.data?.url) {
          history.push(result.data.url);
        }
      },
    }
  );

  const { refetch: login } = useQuery(
    ['login', token],
    async () => {
      return authenticatedPost(`${ENV.API_URL}/login`, {
        token,
      });
    },
    {
      enabled: false,
      onSuccess: async () => {
        await loadUser();
        history.push(url);
      },
    }
  );

  useEffect(() => {
    if (token) {
      login();
    }
  }, [token, login]);

  return <PageLoader />;
}

export function MagicCode() {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { loadUser } = useCurrentUser();
  const [code, setCode] = useState('');
  const [token, setToken] = useState('');
  const [url, setUrl] = useState('');
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    type: '',
  });

  const { refetch: sendMagicCode } = useQuery(
    'sendMagicCode',
    () => {
      return post(`${ENV.API_URL}/urls/code/${code}`);
    },
    {
      enabled: false,
      onSuccess: async (response) => {
        if (response.status === 500) {
          enqueueSnackbar('Failed to find magic URL.', { variant: 'error' });
          history.push('/');
        }

        const result = await response.json();
        if (result.data?.token) {
          setToken(result.data?.token);
          setUrl(result.data?.url);
        } else if (result.data?.url) {
          history.push(result.data.url);
        }
      },
    }
  );

  const { refetch: login } = useQuery(
    ['login', token],
    async () => {
      return authenticatedPost(`${ENV.API_URL}/login`, {
        token,
      });
    },
    {
      enabled: false,
      onSuccess: async () => {
        await loadUser();
        history.push(url);
      },
    }
  );

  useEffect(() => {
    if (token) {
      login();
    }
  }, [token, login]);

  const onChange = (e) => {
    const { value } = e.target;
    const matchedValues = value.match(/\d+/g);
    const updatedValue = matchedValues ? matchedValues.join('') : '';

    setCode(updatedValue);
  };

  return (
    <Box
      className={classes.main}
      display="flex"
      alignItems="center"
      flexDirection="column"
      justifyContent="center"
    >
      <Typography variant="h1" color="primary">
        Code and URL sent!
      </Typography>
      <Typography level="h5" color="primary">
        Please check your text messages. Click the link or enter the code below
        to log in.
      </Typography>
      <FormControl variant="outlined" style={{ margin: '20px 0' }}>
        <InputLabel htmlFor="code">Enter Your Code</InputLabel>
        <OutlinedInput
          id="code"
          value={code}
          onChange={onChange}
          label="Enter Your Code"
          type="text"
          inputMode="numeric"
          autoComplete="one-time-code"
          pattern="\d{8}"
        />
      </FormControl>
      <Button
        variant="contained"
        color="primary"
        disableElevation
        aria-label="Send Login Link"
        onClick={() => {
          if (!code) {
            setSnackbar({
              open: true,
              message: 'Please enter a valid code!',
              type: 'error',
            });
          } else {
            sendMagicCode();
          }
        }}
      >
        Log In
      </Button>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={SNACKBAR_DURATION}
        open={snackbar.open}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert severity={snackbar.type}>{snackbar.message}</Alert>
      </Snackbar>
    </Box>
  );
}
