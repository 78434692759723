import React, { useEffect, useState } from 'react';
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Toolbar,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import Skeleton from '@material-ui/lab/Skeleton';
import { Link, useHistory } from 'react-router-dom';
import { useCurrentUser } from '../context/UserContext';
import logo from '../img/logo-no-background.svg';
import HashIcon from './Icons/HashIcon';
import displayHandle from '../utils/displayHandle';
import { NAVBAR_HEIGHT } from '../constants';
import Hamburger from './Icons/Hamburger';
import SendMessage from './Icons/SendMessage';
import UserAvatar from './GroupView/UserAvatar';

const useStyles = makeStyles((theme) => ({
  offset: { height: NAVBAR_HEIGHT, width: '100%' },
  menu: {
    width: '75vw',
    height: '100vh',
    background: theme.palette.primary.main,
    color: 'white',
  },
  menuList: {
    marginTop: '19px',
    marginLeft: '19px',
  },
  drawerClose: {
    position: 'absolute',
    padding: '0',
    top: '22px',
    right: '27px',
    height: '20px',
    width: '20px',
  },
  toolbar: {
    height: NAVBAR_HEIGHT,
  },
  logoSmall: {
    height: '37px',
    margin: '0 auto',
  },
  logoLarge: {
    height: '40px',
    margin: '22px 0 0 35px',
  },
  bottomArea: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: '100%',
    position: 'absolute',
    bottom: '0',
  },
  bottomButtons: {
    display: 'block',
    width: '100%',
    marginBottom: '14px',
  },
}));

const renderListItemSkeletons = (count) =>
  Array.from(Array(count)).map((_, i) => (
    <ListItem button key={i}>
      <ListItemAvatar>
        <Skeleton variant="circle" animation="wave">
          <Avatar />
        </Skeleton>
      </ListItemAvatar>
      <ListItemText>
        <Skeleton animation="wave" width="90%" />
      </ListItemText>
    </ListItem>
  ));

export default function Nav() {
  const history = useHistory();
  const { loading, user } = useCurrentUser();
  const classes = useStyles();

  const [menuOpen, setMenuOpen] = useState(false);

  const toggleDrawer = (open) => () => {
    setMenuOpen(open);
  };

  const handleSendMessageClick = () => {
    history.push('/message');
  };

  const handleLogOutClick = () => {
    history.push('/logout');
  };

  /**
   * Always close the drawer when navigating.
   */
  useEffect(() => {
    const unlisten = history.listen(() => setMenuOpen(false));

    return () => unlisten();
  }, [history]);

  const renderLinks = () => {
    const links = [
      {
        to: `/user/${user?._id}`,
        text: displayHandle(user),
        icon: (
          <ListItemAvatar>
            <UserAvatar user={user} />
          </ListItemAvatar>
        ),
      },
      {
        to: `/user/${user?._id}/groups`,
        text: 'Groups',
        icon: (
          <ListItemIcon>
            <HashIcon style={{ stroke: 'white' }} />
          </ListItemIcon>
        ),
      },
      {
        to: `/events`,
        text: 'Events',
        icon: (
          <ListItemIcon>
            <CalendarTodayOutlinedIcon style={{ color: 'white' }} />
          </ListItemIcon>
        ),
      },
    ];
    return links.map((link) => (
      <ListItem key={link.text} button component={Link} to={link.to}>
        {link.icon}
        <ListItemText primary={link.text}></ListItemText>
      </ListItem>
    ));
  };

  return (
    <>
      <AppBar position="fixed">
        <Toolbar className={classes.toolbar}>
          {user !== null && (
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer(true)}
            >
              <Hamburger style={{ width: '36px', height: '32px' }} />
            </IconButton>
          )}
          <Link to="/" className={classes.logoSmall}>
            <img src={logo} alt="Amplo logo" className={classes.logoSmall} />
          </Link>
          {user !== null && (
            <IconButton
              edge="end"
              color="inherit"
              aria-label="send message"
              component={Link}
              to={'/message'}
            >
              <SendMessage style={{ width: '36px', height: '36px' }} />
            </IconButton>
          )}
        </Toolbar>
        {user !== null && (
          <Drawer anchor="left" open={menuOpen} onClose={toggleDrawer(false)}>
            <div className={classes.menu}>
              <Link to="/">
                <img
                  src={logo}
                  alt="Amplo logo"
                  className={classes.logoLarge}
                />
              </Link>
              <IconButton
                color="inherit"
                aria-label="close drawer"
                className={classes.drawerClose}
                onClick={toggleDrawer(false)}
              >
                <CloseIcon fontSize="large" />
              </IconButton>
              <List className={classes.menuList}>
                {loading ? renderListItemSkeletons(3) : renderLinks()}
              </List>
              <Box className={classes.bottomArea}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.bottomButtons}
                  disableElevation
                  aria-label="send message"
                  onClick={handleSendMessageClick}
                >
                  Send a Message
                </Button>
                <Button
                  variant="contained"
                  className={classes.bottomButtons}
                  disableElevation
                  aria-label="log out"
                  onClick={handleLogOutClick}
                >
                  Log Out
                </Button>
              </Box>
            </div>
          </Drawer>
        )}
      </AppBar>
      <div className={classes.offset}></div>
    </>
  );
}
