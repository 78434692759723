import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  makeStyles,
  Typography,
} from '@material-ui/core';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { EVENTS } from '../../constants';
import { useCurrentUser } from '../../context/UserContext';
import { EventPropTypes } from '../../utils/useEvent';
import useUser from '../../utils/useUser';
import EditButton from '../Controls/EditButton';
import UserAvatar from '../GroupView/UserAvatar';
import RSVPYesIcon from '../Icons/RSVPYes';
import AddToCalendar from './AddToCalendar';
import EventRSVPStatus from './EventRSVPStatus';

const useStyles = makeStyles((theme) => ({
  cardHeader: {
    paddingTop: '12px',
    paddingRight: '12px',
    paddingBottom: 0,
  },
  cardAction: {
    marginTop: 0,
    marginRight: 0,
  },
  cardTitle: {
    marginLeft: '10px',
  },
  eventDetail: {
    margin: '20px 10px',
  },
  icon: {
    fill: 'none',
    stroke: theme.palette.primary.main,
  },
  addToCalendar: {
    marginTop: 0,
  },
}));

export default function EventDetails({ event, editable }) {
  const classes = useStyles();
  const history = useHistory();
  const routeMatch = useRouteMatch();
  const { user } = useCurrentUser();

  const owner = event?.users.find((u) => u.role === EVENTS.ROLE.OWNER);
  const { data: ownerData } = useUser(owner?.id);

  const ownerName = `${ownerData?.firstName} ${ownerData?.lastName}`;
  const startTime = dayjs(event.startTime);
  const endTime = dayjs(event.endTime);
  const attendees = event.users.filter((u) => u.status === EVENTS.STATUS.YES);

  return (
    <Container>
      <Card className={classes.card}>
        <CardHeader
          classes={{
            root: classes.cardHeader,
            action: classes.cardAction,
          }}
          action={
            editable && (
              <EditButton
                onClick={() => history.push(`/events/update/${event._id}`)}
              />
            )
          }
        />
        <CardContent>
          <Typography
            color="primary"
            variant="h2"
            className={classes.cardTitle}
          >
            {event.title}
          </Typography>
          <Box
            display="flex"
            alignItems="flex-start"
            className={classes.eventDetail}
          >
            <CalendarTodayOutlinedIcon color="primary" />
            <Box
              display="flex"
              flexDirection="column"
              style={{ marginLeft: '20px' }}
            >
              <Typography color="primary" variant="h5">
                {startTime.format('dddd, MMM D, YYYY')}
              </Typography>
              <Typography color="primary" variant="h5">
                {startTime.format('h:mm A')} - {endTime.format('h:mm A')}
              </Typography>
            </Box>
          </Box>
          <Box
            display="flex"
            alignItems="flex-start"
            className={classes.eventDetail}
          >
            <RoomOutlinedIcon color="primary" />
            <Box style={{ marginLeft: '20px' }}>
              <Typography color="primary" variant="h5">
                {event.location}
              </Typography>
            </Box>
          </Box>
          <Box
            display="flex"
            alignItems="flex-start"
            className={classes.eventDetail}
            onClick={() => history.push(`${routeMatch.url}/attendees`)}
          >
            <RSVPYesIcon className={classes.icon} />
            <Box
              display="flex"
              flexDirection="column"
              style={{ marginLeft: '20px' }}
            >
              <Typography color="primary" variant="h5">
                {attendees.length === 1
                  ? '1 Person'
                  : `${attendees.length} People`}{' '}
                Going
              </Typography>
            </Box>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            className={classes.eventDetail}
          >
            <EventRSVPStatus event={event} userId={user?._id} />
          </Box>
          <Card style={{ margin: '0 5px' }}>
            <CardHeader
              avatar={
                <UserAvatar aria-label="user-avatar" user={owner} small />
              }
              title={
                <Typography color="primary" variant="h5">
                  {ownerName}
                </Typography>
              }
            ></CardHeader>
            <CardContent style={{ paddingTop: 0 }}>
              <Typography variant="body2">{event.description}</Typography>
            </CardContent>
          </Card>
        </CardContent>
        <Container className={classes.addToCalendar}>
          <AddToCalendar eventId={event._id} type="secondary" />
        </Container>
      </Card>
    </Container>
  );
}

EventDetails.propTypes = {
  event: EventPropTypes.isRequired,
  editable: PropTypes.bool,
};
