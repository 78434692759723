import {
  Avatar,
  Box,
  Card,
  CardContent,
  makeStyles,
  Typography,
} from '@material-ui/core';
import DoneRoundedIcon from '@material-ui/icons/DoneRounded';
import React from 'react';
import PropTypes from 'prop-types';
import { ENV, PAGE_HEIGHT } from '../../constants';

const useStyles = makeStyles(() => ({
  main: {
    backgroundColor: '#dcf7f4',
    minHeight: PAGE_HEIGHT,
    padding: '33px 28px',
    textAlign: 'center',
  },
  check: {
    margin: '0 auto',
    marginTop: '28px',
    marginBottom: '16px',
    width: '48px',
    height: '48px',
    backgroundColor: '#8afcda',
    color: '#2b2f51',
  },
  setUp: {
    marginBottom: '24px',
  },
  highlight: {
    backgroundColor: '#8afcda',
    padding: '4px 0',
    marginLeft: '16px',
    marginRight: '16px',
  },
  subHeader: {
    marginTop: '24px',
    marginBottom: '18px',
  },
  iconWrapper: {
    margin: '14px 0 12px 0',
    '& *': {
      margin: '0 6px',
    },
  },
  secondaryCard: {
    marginBottom: '14px',
  },
}));

export default function CreateSuccess({
  name,
  message,
  cta,
  quickGuide,
  children,
}) {
  const classes = useStyles();
  return (
    <Box className={classes.main}>
      <Card>
        <CardContent>
          <Avatar className={classes.check}>
            <DoneRoundedIcon fontSize="large" />
          </Avatar>
          <Typography variant="h3" compontent="h1" className={classes.setUp}>
            {message}{' '}
            <span role="img" aria-label="celebrate">
              🎉
            </span>
          </Typography>
          <Typography variant="h4" component="div">
            Tell people to text
          </Typography>
          <Typography
            variant="h3"
            component="div"
            className={classes.highlight}
          >
            {name} to {ENV.SHORT_CODE}
          </Typography>
          <Typography variant="h4" component="div">
            {cta}
          </Typography>
        </CardContent>
      </Card>
      <Typography variant="h3" component="h2" className={classes.subHeader}>
        Quick Guide to {quickGuide}
      </Typography>
      {children}
    </Box>
  );
}

CreateSuccess.propTypes = {
  name: PropTypes.string,
  message: PropTypes.string,
  cta: PropTypes.string,
  quickGuide: PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.node),
};
