import React from 'react';
import PropTypes from 'prop-types';
import { Box, makeStyles, Typography, useTheme } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import CloseIcon from '@material-ui/icons/Close';

import { EventPropTypes } from '../../utils/useEvent';
import { EVENTS } from '../../constants';

const useStyles = makeStyles({
  icon: {},
});

export default function EventRSVPStatus({ event, userId }) {
  const classes = useStyles();
  const theme = useTheme();

  const eventUser = event?.users.find((u) => userId === u.id);

  if (!eventUser) return null;

  let RSVPStatusIcon;
  let iconColor;
  let rsvpStatus;

  switch (eventUser.status) {
    case EVENTS.STATUS.YES:
      RSVPStatusIcon = CheckIcon;
      iconColor = theme.palette.success.main;
      rsvpStatus = 'You Are Going';
      break;
    case EVENTS.STATUS.MAYBE:
      RSVPStatusIcon = HelpOutlineIcon;
      iconColor = theme.palette.primary.main;
      rsvpStatus = `You're a Maybe`;
      break;
    case EVENTS.STATUS.NO:
      RSVPStatusIcon = CloseIcon;
      iconColor = theme.palette.error.main;
      rsvpStatus = 'You Are Unable to Go';
      break;
    default:
      break;
  }

  return (
    <Box display="flex" alignItems="center">
      <RSVPStatusIcon
        className={classes.icon}
        fill={iconColor}
        stroke={iconColor}
        style={{ height: '20px' }}
      />
      <Typography color="primary" variant="h6">
        {rsvpStatus}
      </Typography>
    </Box>
  );
}

EventRSVPStatus.propTypes = {
  event: EventPropTypes,
  userId: PropTypes.string,
};
