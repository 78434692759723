import { SvgIcon } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as Message } from '../../img/message.svg';
import { ReactComponent as MessageAt } from '../../img/message-at.svg';
import { ReactComponent as MessageAtFlip } from '../../img/message-at-flip.svg';
import { ReactComponent as MessageHash } from '../../img/message-hash.svg';

export default function MessageIcon(props) {
  const { style, at, hash, flip, ...rest } = props;

  let icon = Message;
  if (at) {
    icon = flip ? MessageAtFlip : MessageAt;
  } else if (hash) {
    icon = MessageHash;
  }

  return (
    <SvgIcon
      {...rest}
      component={icon}
      viewBox="0 0 52 52"
      style={{ color: 'rgba(0,0,0,0)', ...style }}
    />
  );
}

MessageIcon.propTypes = {
  style: PropTypes.any,
  at: PropTypes.bool,
  flip: PropTypes.bool,
  hash: PropTypes.bool,
};
