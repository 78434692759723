import { useSnackbar } from 'notistack';
import { useQuery } from 'react-query';
import { ENV } from '../constants';
import { authenticatedGet } from './authenticatedFetch';

export default function useUser(id) {
  const { enqueueSnackbar } = useSnackbar();

  return useQuery(
    `user?.${id}`,
    async () => {
      const response = await authenticatedGet(`${ENV.API_URL}/user/${id}`);

      if (!response.ok) {
        throw Error('Error retrieving user data');
      }

      const result = await response.json();

      return result.user;
    },
    {
      onError(response) {
        enqueueSnackbar(response.message, { variant: 'error' });
      },
    }
  );
}
