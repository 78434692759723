import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  FormControl,
  InputLabel,
  makeStyles,
  OutlinedInput,
  TextField,
  Button,
  Box,
  Tooltip,
  Select,
  MenuItem,
  Dialog,
  DialogContent,
  DialogActions,
  Paper,
} from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import { useHistory, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { DatePicker, TimePicker } from '@material-ui/pickers';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import { ENV, EVENTS } from '../../constants';
import useEvent from '../../utils/useEvent';
import { authenticatedPost } from '../../utils/authenticatedFetch';
import Header from '../Header';
import AmploSwitch from '../Controls/AmploSwitch';
import { useCurrentUser } from '../../context/UserContext';
import useEvents from '../../utils/useEvents';

const useStyles = makeStyles((theme) => ({
  main: {
    backgroundColor: 'white',
    padding: '0 20px',
  },
  quickFillItem: {
    boxShadow: '0px 2px 9px rgba(0, 0, 0, 0.17)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '10px',
    margin: '10px',
    width: '200px',
  },
  quickFillItemText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  quickFillSelected: {
    backgroundColor: theme.palette.primary.main,
    boxShadow: 'none',
  },
  quickFillSelectedText: {
    color: 'white',
  },
  form: {
    height: '100%',
  },
  formItem: {
    paddingBottom: '20px',
    width: '100%',
  },
  bottomControls: {
    position: 'fixed',
    bottom: '0',
    left: 0,
    right: 0,
    backgroundColor: 'white',
    padding: '20px',
    zIndex: '100',
  },
  formSwitch: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cancelButton: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
  },
  cancelConfirmButton: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.white,
  },
  popup: {
    padding: '10px',
  },
}));

const REMIND_DEFAULT = {
  on: false,
  message: '',
  duration: 600000, // 10 minutes
};

export default function CreateEvent() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const now = dayjs();
  const { id } = useParams();
  const history = useHistory();
  const { data: event } = useEvent(id);
  const [title, setTitle] = useState('');
  const [location, setLocation] = useState('');
  const [description, setDescription] = useState('');
  const [date, setDate] = useState(now);
  const [startTime, setStartTime] = useState(
    now.add(1, 'hour').startOf('hour')
  );
  const [endTime, setEndTime] = useState(now.add(2, 'hour').startOf('hour'));
  const [rsvp, setRsvp] = useState(false);
  const [remind, setRemind] = useState(REMIND_DEFAULT);
  const [quickFillSelected, setQuickFillSelected] = useState(false);
  const [notifyPopup, setNotifyPopup] = useState(false);
  const [cancelPopup, setCancelPopup] = useState(false);

  const skipEventsQuery = Boolean(id);
  const quickFill = useEvents(
    {
      limit: 5,
      owner: true,
      after: dayjs().startOf('day').subtract(1, 'month'),
    },
    skipEventsQuery
  );

  const { user, loading: userLoading } = useCurrentUser();

  function setFormData(formData) {
    setTitle(formData.title);
    setLocation(formData.location);
    setDescription(formData.description);
    setDate(dayjs(formData.startTime));
    setStartTime(dayjs(formData.startTime));
    setEndTime(dayjs(formData.endTime));
    setRsvp(formData.rsvp);
    setRemind(formData.remind || REMIND_DEFAULT);
  }

  function setFormValue(value, setState) {
    setState(value);
    setQuickFillSelected(false);
  }

  useEffect(() => {
    // Event doesn't exist
    if (id && event === undefined) {
      enqueueSnackbar(`Event doesn't exist`, { variant: 'error' });
      history.push('/events');
    }
    if (event) {
      setFormData(event);
    }
  }, [event, id, history, enqueueSnackbar]);

  async function onSubmit({ notify } = {}) {
    if (dayjs(startTime).isAfter(dayjs(endTime))) {
      enqueueSnackbar('Invalid time range', { variant: 'error' });
      return;
    }
    const start = date
      .set({
        hour: startTime.get('hour'),
        minute: startTime.get('minute'),
        second: 0,
        millisecond: 0,
      })
      .toISOString();
    const end = date
      .set({
        hour: endTime.get('hour'),
        minute: endTime.get('minute'),
        second: 0,
        millisecond: 0,
      })
      .toISOString();

    try {
      // If success, direct to success page
      // If error, direct to error page?
      const response = await authenticatedPost(
        `${ENV.API_URL}/events/${id ? `update/${id}` : 'create'}`,
        {
          title,
          description,
          location,
          rsvp,
          remind: rsvp ? remind : REMIND_DEFAULT,
          startTime: start,
          endTime: end,
          notify,
        }
      );
      const result = await response.json();
      if (result.success) {
        history.push(`/events/event/${id || result.data.insertedId}`, {
          fromCreate: !id,
          fromUpdate: !!id,
        });
      }
    } catch (err) {
      console.error(err);
    }
  }

  const titleText = id ? 'Edit Event' : 'Set Up an Event';
  const formDisabled = !title || !date || !startTime || !endTime || !location;

  // If editing existing event and user is not owner,
  // Redirect to event page
  if (
    event &&
    !userLoading &&
    event.users.find((u) => u.id === user?._id)?.role !== EVENTS.ROLE.OWNER
  ) {
    history.replace(`/events/event/${id}`);
  }

  return (
    <Box>
      <Header back={Boolean(id)} title={titleText} />
      <Box className={classes.main}>
        {quickFill.data?.length ? (
          <Box>
            <Typography color="primary" variant="h4">
              Quick Fill
            </Typography>
            <Box
              display="flex"
              style={{
                margin: '0 -20px 10px',
                overflowX: 'auto',
                paddingBottom: '10px',
              }}
            >
              <Box display="flex" style={{ padding: '0 10px' }}>
                {quickFill.data.map((quickFillEvent) => {
                  let startDate = dayjs(quickFillEvent.startTime);
                  let endDate = dayjs(quickFillEvent.endTime);

                  const start = startDate.format('ddd, h:mmA');
                  const end = endDate.format('h:mmA');
                  const selected = quickFillSelected === quickFillEvent._id;
                  return (
                    <Box
                      key={quickFillEvent._id}
                      className={clsx(
                        { [classes.quickFillSelected]: selected },
                        classes.quickFillItem
                      )}
                      onClick={() => {
                        // If event was in the past, update the date
                        // To the next date on the same day of the week
                        if (startDate.isBefore(now)) {
                          const startDayDiff =
                            (startDate.day() - now.day() + 7) % 7;
                          startDate = startDate
                            .set('year', now.get('year'))
                            .set('month', now.get('month'))
                            .set('date', now.get('date'))
                            .add(startDayDiff, 'day');

                          const endDayDiff =
                            (endDate.day() - now.day() + 7) % 7;
                          endDate = endDate
                            .set('year', now.get('year'))
                            .set('month', now.get('month'))
                            .set('date', now.get('date'))
                            .add(endDayDiff, 'day');
                        }
                        setFormData({
                          ...quickFillEvent,
                          startTime: startDate,
                          endTime: endDate,
                        });
                        setQuickFillSelected(quickFillEvent._id);
                      }}
                    >
                      <Typography
                        color="primary"
                        variant="h6"
                        className={clsx(
                          { [classes.quickFillSelectedText]: selected },
                          classes.quickFillItemText
                        )}
                      >
                        {quickFillEvent.title}
                      </Typography>
                      <Typography
                        color="primary"
                        variant="body2"
                        className={clsx(
                          { [classes.quickFillSelectedText]: selected },
                          classes.quickFillItemText
                        )}
                      >
                        {start}-{end}
                      </Typography>
                      <Typography
                        color="primary"
                        variant="body2"
                        className={clsx(
                          { [classes.quickFillSelectedText]: selected },
                          classes.quickFillItemText
                        )}
                      >
                        {quickFillEvent.location}
                      </Typography>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          </Box>
        ) : null}
        <form className={classes.form}>
          <FormControl className={classes.formItem} variant="outlined">
            <InputLabel htmlFor="event-name">Event Name</InputLabel>
            <OutlinedInput
              id="event-name"
              value={title}
              onChange={(e) => setFormValue(e.target.value, setTitle)}
              label="Event Name"
            />
          </FormControl>
          <DatePicker
            className={classes.formItem}
            label="Date"
            inputVariant="outlined"
            value={date}
            onChange={(d) => setFormValue(d, setDate)}
            disableToolbar
          />
          <Box
            className={classes.formItem}
            style={{ display: 'flex', width: '100%' }}
          >
            <TimePicker
              style={{ marginRight: 5 }}
              label="Start Time"
              inputVariant="outlined"
              value={startTime}
              onChange={(t) => setStartTime(t)}
            />
            <TimePicker
              style={{ marginLeft: 5 }}
              label="End Time"
              inputVariant="outlined"
              value={endTime}
              onChange={(t) => setFormValue(t, setEndTime)}
            />
          </Box>
          <FormControl className={classes.formItem} variant="outlined">
            <InputLabel htmlFor="location">Location</InputLabel>
            <OutlinedInput
              id="location"
              value={location}
              onChange={(e) => setFormValue(e.target.value, setLocation)}
              label="Location"
            />
          </FormControl>
          <FormControl className={classes.formItem}>
            <TextField
              id="description"
              value={description}
              onChange={(e) => setFormValue(e.target.value, setDescription)}
              label="Description"
              multiline
              rows={4}
              variant="outlined"
            />
          </FormControl>
          <FormControl
            className={classes.formSwitch}
            style={{
              paddingBottom: '20px',
            }}
          >
            <Box display="flex" alignItems="center">
              <Tooltip
                title="
                By checking this, people who have the link to
                this event will be able to RSVP and receive
                text messages about changes to this event.
                You will be able to see the RSVP list.
              "
                arrow
              >
                <Box
                  display="flex"
                  alignItems="center"
                  style={{ width: '120px', height: '40px' }}
                >
                  <Typography
                    color="primary"
                    variant="h4"
                    style={{ marginRight: '5px' }}
                  >
                    RSVP
                  </Typography>
                  <HelpIcon color="disabled" />
                </Box>
              </Tooltip>
            </Box>
            <AmploSwitch
              checked={rsvp}
              onChange={() => setFormValue(!rsvp, setRsvp)}
            />
          </FormControl>
          {rsvp ? (
            <FormControl
              className={classes.formSwitch}
              style={{ paddingBottom: '20px' }}
            >
              <Box display="flex" alignItems="center">
                <Typography
                  color="primary"
                  variant="h4"
                  style={{ marginRight: '5px' }}
                >
                  Remind Attendees
                </Typography>
              </Box>
              <AmploSwitch
                checked={remind.on}
                onChange={() =>
                  setFormValue({ ...remind, on: !remind.on }, setRemind)
                }
              />
            </FormControl>
          ) : null}
          {rsvp && remind.on ? (
            <FormControl variant="outlined" className={classes.formItem}>
              <InputLabel id="remind-duration-label">Send Message</InputLabel>
              <Select
                labelId="remind-duration-label"
                id="remind-duration"
                value={remind.duration}
                onChange={(e) =>
                  setFormValue(
                    { ...remind, duration: Number(e.target.value) },
                    setRemind
                  )
                }
                label="Send Message"
              >
                <MenuItem value={10 * 60 * 1000}>
                  10 minutes before event
                </MenuItem>
                <MenuItem value={30 * 60 * 1000}>
                  30 minutes before event
                </MenuItem>
                <MenuItem value={1 * 60 * 60 * 1000}>
                  1 hour before event
                </MenuItem>
                <MenuItem value={3 * 60 * 60 * 1000}>
                  3 hours before event
                </MenuItem>
              </Select>
            </FormControl>
          ) : null}
          {rsvp && remind.on ? (
            <FormControl className={classes.formItem}>
              <TextField
                id="remind-message"
                value={remind.message}
                onChange={(e) =>
                  setFormValue(
                    { ...remind, message: e.target.value },
                    setRemind
                  )
                }
                label="Message"
                multiline
                rows={4}
                variant="outlined"
              />
            </FormControl>
          ) : null}
          {id ? (
            <Paper className={classes.bottomControls} elevation={3}>
              <Box
                style={{ display: 'flex', paddingBottom: 10, width: '100%' }}
              >
                <Button
                  style={{ marginRight: 5, flex: 1 }}
                  variant="contained"
                  onClick={() => history.goBack()}
                  disableElevation
                >
                  Cancel
                </Button>
                <Button
                  style={{ marginLeft: 5, flex: 1 }}
                  variant="contained"
                  color="primary"
                  disabled={formDisabled}
                  onClick={() =>
                    event.rsvp ? setNotifyPopup(true) : onSubmit()
                  }
                  disableElevation
                >
                  Save Changes
                </Button>
              </Box>
              <Button
                style={{ width: '100%' }}
                className={classes.cancelButton}
                variant="outlined"
                onClick={() => setCancelPopup(true)}
                disableElevation
              >
                Cancel Event
              </Button>
            </Paper>
          ) : (
            <Box className={classes.formItem}>
              <Button
                variant="contained"
                color="primary"
                style={{ width: '100%' }}
                disabled={formDisabled}
                onClick={() => onSubmit()}
                disableElevation
              >
                Create Event
              </Button>
            </Box>
          )}
        </form>
      </Box>
      <NotifyDialog
        open={notifyPopup}
        onClose={() => setNotifyPopup(false)}
        onSubmit={onSubmit}
      />
      <CancelDialog
        open={cancelPopup}
        onClose={() => setCancelPopup(false)}
        eventId={id}
      />
    </Box>
  );
}

CreateEvent.propTypes = {
  id: PropTypes.string,
};

function NotifyDialog({ onClose, open, onSubmit }) {
  const classes = useStyles();

  return (
    <Dialog classes={{ paper: classes.popup }} open={open} onClose={onClose}>
      <DialogContent>
        <Typography
          color="primary"
          variant="h4"
          style={{ textAlign: 'center' }}
        >
          Notify the RSVP List of these changes?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onClose();
            onSubmit({ notify: false });
          }}
          variant="contained"
          disableElevation
        >
          No
        </Button>
        <Button
          onClick={() => {
            onClose(false);
            onSubmit({ notify: true });
          }}
          color="primary"
          variant="contained"
          disableElevation
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}

NotifyDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

function CancelDialog({ eventId, open, onClose }) {
  const history = useHistory();
  const classes = useStyles();

  async function onCancel() {
    try {
      // If success, direct to events page
      const response = await authenticatedPost(
        `${ENV.API_URL}/events/cancel/${eventId}`
      );
      const result = await response.json();
      if (result.success) {
        history.push('/events');
      }
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <Dialog classes={{ paper: classes.popup }} open={open} onClose={onClose}>
      <DialogContent>
        <Typography
          color="primary"
          variant="h4"
          style={{ textAlign: 'center' }}
        >
          Cancel event?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined" disableElevation>
          No
        </Button>
        <Button
          className={classes.cancelConfirmButton}
          onClick={() => {
            onClose();
            onCancel();
          }}
          variant="contained"
          disableElevation
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}

CancelDialog.propTypes = {
  eventId: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};
