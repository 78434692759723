import { Box, Button, Link, makeStyles } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useCurrentUser } from '../context/UserContext';
import logo from '../img/logo-tagline.svg';
import displayHandle from '../utils/displayHandle';
import HashIcon from './Icons/HashIcon';

const useStyles = makeStyles((theme) => ({
  footerContainer: {
    backgroundColor: theme.palette.primary.main,
    paddingTop: '36px',
    paddingBottom: '33px',
    padding: '36px 22px 33px 22px',
  },
  buttons: {
    display: 'block',
    width: '100%',
    marginBottom: '24px',
    textAlign: 'center',
  },
  logo: {
    margin: '0 auto',
    marginTop: '5px',
    width: '107px',
    display: 'block',
  },
}));

export default function Footer() {
  const classes = useStyles();
  const { loading, user } = useCurrentUser();
  const history = useHistory();

  let buttons = [
    {
      text: 'Quick Guides',
      target: '/quick-guides',
    },
    {
      text: 'FAQ',
      target: '/faq',
    },
    {
      text: 'Support',
      target: '/support',
    },
  ];

  if (!loading && user !== null) {
    // Add the user-specific buttons
    buttons = [
      {
        text: displayHandle(user),
        target: `/user/${user?._id}`,
      },
      {
        text: (
          <Box display="flex" justifyContent="center">
            <HashIcon style={{ stroke: 'white' }} /> Groups{' '}
          </Box>
        ),
        target: `/user/${user?._id}/groups`,
      },
      ...buttons,
    ];
  } else {
    // Add the sign-up and log in buttons
    buttons = [
      { text: 'Sign-Up', color: 'primary', target: '/signup' },
      { text: 'Log In', color: 'secondary', target: '/login' },
      ...buttons,
    ];
  }

  return (
    <Box className={classes.footerContainer}>
      {buttons.map((button) => (
        <Button
          key={button.text}
          variant="contained"
          disableElevation
          color={button.color}
          className={classes.buttons}
          onClick={() => history.push(button.target)}
        >
          {button.text}
        </Button>
      ))}
      <Button
        variant="contained"
        disableElevation
        className={classes.buttons}
        component={Link}
        href="https://myy.org/privacy-policy/"
        target="_blank"
        rel="noopener"
      >
        Privacy Policy
      </Button>
      <img src={logo} alt="Amplo logo" className={classes.logo} />
    </Box>
  );
}
