import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import {
  Box,
  FormControl,
  InputLabel,
  makeStyles,
  OutlinedInput,
  Snackbar,
  Typography,
} from '@material-ui/core';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import Alert from '@material-ui/lab/Alert';
import { post } from '../../../utils/fetch';
import { ENV, SNACKBAR_DURATION } from '../../../constants';
import { useCurrentUser } from '../../../context/UserContext';
import { authenticatedPost } from '../../../utils/authenticatedFetch';

const useStyles = makeStyles({
  main: {
    backgroundColor: 'white',
    padding: '18px 22px',
    textAlign: 'center',
  },
  textInput: {
    margin: '20px 0',
  },
});

export default function RSVPVerification() {
  const { loadUser } = useCurrentUser();
  const classes = useStyles();
  const [code, setCode] = useState('');
  const [token, setToken] = useState('');
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    type: 'success',
  });

  const { refetch: login } = useQuery(
    'login',
    () =>
      authenticatedPost(`${ENV.API_URL}/login`, {
        token,
      }),
    {
      onSuccess: () => {
        loadUser();
      },
      enabled: false,
    }
  );

  const { refetch: submitCode, data, isLoading } = useQuery(
    'verifyLogin',
    async () => {
      const response = await post(`${ENV.API_URL}/urls/code/${code}`);
      return response.json();
    },
    {
      enabled: false,
      onSuccess: () => {},
      onError: () => {
        setSnackbar({
          open: true,
          message: 'Something happened...',
          type: 'error',
        });
      },
    }
  );

  useEffect(() => {
    if (!isLoading && data) {
      setToken(data.data.token);
    }
  }, [data, isLoading]);

  useEffect(() => {
    if (code.length === 8) {
      submitCode();
    }
  }, [code, submitCode]);

  useEffect(() => {
    if (token) {
      login();
    }
  }, [token, login]);

  return (
    <Box className={classes.main}>
      <ChatBubbleOutlineIcon color="secondary" style={{ fontSize: '40px' }} />
      <Typography color="primary" variant="h3">
        Verify your phone number
      </Typography>
      <Typography color="primary" variant="h5">
        You should get a text with an 8-digit code. Enter your code here
      </Typography>
      <FormControl className={classes.textInput} variant="outlined">
        <InputLabel htmlFor="code">Code</InputLabel>
        <OutlinedInput
          id="code"
          value={code}
          onChange={(e) => setCode(e.target.value)}
          label="Code"
          type="text"
          inputMode="numeric"
          autoComplete="one-time-code"
          pattern="\d{8}"
        />
      </FormControl>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={SNACKBAR_DURATION}
        open={snackbar.open}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert severity={snackbar.type}>{snackbar.message}</Alert>
      </Snackbar>
    </Box>
  );
}
