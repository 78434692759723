import { CircularProgress, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  base: {
    display: 'flex',
    alignItems: 'center',
    '& span': {
      paddingRight: '6px',
    },
  },
  valid: {
    color: theme.palette.success.dark,
  },
  warning: {
    color: theme.palette.gray.dark,
  },
  inValid: {
    color: theme.palette.error.main,
  },
}));

export default function ValidityIndicator({ isValid, text }) {
  const classes = useStyles();
  const invalid = isValid !== undefined && !isValid;
  let icon = <CircularProgress size={16} />;
  if (invalid) {
    icon = <ErrorOutlineRoundedIcon />;
  } else if (isValid) {
    icon = <CheckRoundedIcon />;
  }
  return text ? (
    <Typography
      className={clsx(
        classes.base,
        isValid && classes.valid,
        invalid && classes.inValid,
        !isValid && !invalid && classes.warning
      )}
    >
      <span>{text}</span>
      {icon}
    </Typography>
  ) : null;
}

ValidityIndicator.propTypes = {
  isValid: PropTypes.bool,
  text: PropTypes.string,
};
