// TODO: Better error handling
// Aborting a fetch will throw an AbortError

export default function authenticatedFetch(url, options) {
  return fetch(url, {
    credentials: 'include',
    ...options,
  });
}

export function authenticatedGet(url, options) {
  return authenticatedFetch(url, {
    method: 'GET',
    ...options,
  });
}

export function authenticatedPost(url, data, options) {
  return authenticatedFetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
    ...options,
  });
}
