import { SvgIcon } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as HamburgerIcon } from '../../img/hamburger.svg';

export default function UserIcon(props) {
  const { style, ...rest } = props;

  return (
    <SvgIcon
      {...rest}
      component={HamburgerIcon}
      viewBox={'0 0 36 32'}
      style={{ color: 'rgba(0,0,0,0)', ...style }}
    />
  );
}

UserIcon.propTypes = {
  style: PropTypes.any,
};
