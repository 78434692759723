import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  List,
  ListItem,
  makeStyles,
  Typography,
  useTheme,
} from '@material-ui/core';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { EVENTS, PAGE_HEIGHT } from '../../constants';
import useEvents from '../../utils/useEvents';
import useUser from '../../utils/useUser';
import PageLoader from '../PageLoader';

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: 'white',
    boxShadow: '0px 4px 6px -3px rgba(0, 0, 0, 0.2)',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '20px',
    position: 'relative',
  },
  main: {
    backgroundColor: '#f2fffd',
    height: '100%',
    padding: '20px',
  },
  listHeader: {
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'center',
    padding: '2px 0',
  },
  listItem: {
    padding: '10px 0',
  },
  card: {
    boxShadow: '0px 4px 6px -1px rgba(0, 0, 0, 0.2)',
    height: '110px',
    width: '100%',
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    padding: '5px !important',
    width: '100%',
  },
  date: {
    alignItems: 'center',
    backgroundColor: theme.palette.gray.main,
    display: 'flex',
    flex: '0 0 100px',
    flexDirection: 'column',
    height: '100px',
    justifyContent: 'center',
    maxWidth: '100px',
  },
  dateNum: {
    fontSize: '60px',
    fontWeight: '500',
    lineHeight: '50px',
  },
  eventInfo: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-around',
    margin: '10px 20px',
  },
}));

function renderEvent(event, i) {
  const { _id: eventId, title, startTime, users, endTime } = event;
  return (
    <EventListItem
      key={i}
      eventId={eventId}
      title={title}
      users={users}
      endTime={endTime}
      startTime={startTime}
    />
  );
}

function EventListItem({ eventId, title, users, startTime, endTime }) {
  const classes = useStyles();
  const history = useHistory();
  const startDate = dayjs(startTime);
  const endDate = dayjs(endTime);
  const dayOfWeek = startDate.format('ddd');
  const dateNum = startDate.format('D');
  const owner = users.find((u) => u.role === EVENTS.ROLE.OWNER);
  const { data: userData } = useUser(owner?.id);

  const start = startDate.format('h:mm a');
  const end = endDate.format('h:mm a');

  return (
    <ListItem
      className={classes.listItem}
      onClick={() => history.push(`/events/event/${eventId}`)}
    >
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Box className={classes.date}>
            <Typography color="primary" variant="subtitle1">
              {dayOfWeek}
            </Typography>
            <Typography color="primary" className={classes.dateNum}>
              {dateNum}
            </Typography>
          </Box>
          <Box className={classes.eventInfo}>
            <Typography color="primary" variant="h4">
              {title}
            </Typography>
            <Typography color="primary" variant="h5">
              {start} - {end}
            </Typography>
            {userData ? (
              <Typography color="primary" variant="h5">
                {[userData.firstName, userData.lastName].join(' ')}
              </Typography>
            ) : null}
          </Box>
        </CardContent>
      </Card>
    </ListItem>
  );
}

EventListItem.propTypes = {
  title: PropTypes.string,
  eventId: PropTypes.string,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      role: PropTypes.string,
      id: PropTypes.string,
    })
  ),
  endTime: PropTypes.string,
  startTime: PropTypes.string,
};

function EventListHeader({ title }) {
  const classes = useStyles();
  return (
    <Typography
      color="secondary"
      className={classes.listHeader}
      variant="subtitle1"
    >
      {title}
    </Typography>
  );
}

EventListHeader.propTypes = {
  title: PropTypes.string.isRequired,
};

function getGroupedEvents(events) {
  const now = dayjs();
  const futureEvents = events.filter(
    (event) => !dayjs(event.startTime).isBefore(now)
  );
  const groupedEvents = {};

  let date = dayjs(now);
  const last = futureEvents.length
    ? dayjs(futureEvents[futureEvents.length - 1].startTime)
    : date;

  do {
    const yearMonth = dayjs(date).format('YYYY-MM');

    groupedEvents[yearMonth] = [];

    date = date.add(1, 'month');
  } while (last.isSame(date, 'month') || last.isAfter(date, 'month'));

  for (let i = 0; i < futureEvents.length; i += 1) {
    const event = futureEvents[i];
    const yearMonth = dayjs(event.startTime).format('YYYY-MM');

    groupedEvents[yearMonth].push(event);
  }

  return groupedEvents;
}

function EventsEmptyState() {
  const theme = useTheme();
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      style={{ margin: '40px 0' }}
    >
      <Typography variant="h3" style={{ color: theme.palette.gray.dark }}>
        No upcoming events
      </Typography>
    </Box>
  );
}

export default function Events() {
  const history = useHistory();
  const events = useEvents();

  const classes = useStyles();

  if (!events || events.isLoading) {
    return <PageLoader />;
  }

  function handleCreateEventClick() {
    history.push('/events/create');
  }

  const groupedEvents = getGroupedEvents(events.data);

  return (
    <Box style={{ height: PAGE_HEIGHT }}>
      <Box className={classes.header}>
        <Typography color="primary" variant="h2">
          Events
        </Typography>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          aria-label="create new Event"
          onClick={handleCreateEventClick}
        >
          New Event
        </Button>
      </Box>
      <Container>
        {Object.keys(groupedEvents).map((date) => {
          const month = date.split('-')[1]; // 01, 02, ..., 12
          const monthNum = Number(month) - 1; // 0, 1, ..., 11
          const monthStr = months[monthNum];

          const monthEvents = groupedEvents[date];

          return (
            <List key={date} subheader={<EventListHeader title={monthStr} />}>
              {monthEvents.length ? (
                monthEvents.map(renderEvent)
              ) : (
                <EventsEmptyState />
              )}
            </List>
          );
        })}
      </Container>
    </Box>
  );
}
