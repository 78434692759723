import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Button, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  usernameBox: {
    backgroundColor: theme.palette.secondary.lightDisabled,
    padding: '28px 22px 35px',
  },
  button: {
    margin: '12px 0',
  },
}));

export default function RSVPSignUp() {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Box
      className={classes.usernameBox}
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Typography variant="h3" color="primary">
        Create a Username
      </Typography>
      <Button
        className={classes.button}
        onClick={() => history.push(`/signup`)}
        variant="contained"
        fullWidth
        disableElevation
      >
        Sign-up
      </Button>
      <Typography variant="h4" color="primary">
        {"You're already halfway there."}
      </Typography>
    </Box>
  );
}
