import {
  Box,
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Typography,
  useTheme,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import { ENV, PAGE_HEIGHT } from '../constants';
import { authenticatedGet } from '../utils/authenticatedFetch';
import Header from './Header';
import HashIcon from './Icons/HashIcon';
import { useCurrentUser } from '../context/UserContext';
import PageLoader from './PageLoader';

const useStyles = makeStyles(() => ({
  groupItem: {
    padding: '20px 35px',
  },
}));

export default function GroupList() {
  const classes = useStyles();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const history = useHistory();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useCurrentUser();

  useEffect(() => {
    const controller = new AbortController();
    setLoading(true);

    (async () => {
      try {
        const response = await authenticatedGet(
          `${ENV.API_URL}/user/${id}/groups?all=true`,
          {
            signal: controller.signal,
          }
        );

        const groupResponse = await response.json();
        if (!groupResponse.userGroups) {
          throw new Error();
        }

        setData(groupResponse.userGroups);
        setLoading(false);
      } catch (err) {
        enqueueSnackbar('Failed to load groups', { variant: 'error' });
        if (err.name !== 'AbortError') {
          setLoading(false);
        }
      }
    })();

    return () => {
      setLoading(false);
      controller.abort();
    };
  }, [id, setLoading, enqueueSnackbar]);

  function onCreateClick() {
    history.push('/group/create');
  }

  function groupClick(groupId) {
    history.push(`/group/${groupId}`);
  }

  return loading ? (
    <PageLoader />
  ) : (
    <Box
      display="flex"
      flexDirection="column"
      style={{ height: PAGE_HEIGHT, overflow: 'hidden' }}
    >
      <Header title="Groups" shadow 
        action={
          user.allowCreateGroup && <Button
            variant="contained"
            color="primary"
            disableElevation
            aria-label="create new Group"
            onClick={onCreateClick}
          >
            New Group
          </Button>
        }/>
      {!loading && data.length > 0 ? (
        <>
          <List style={{ overflow: 'auto', flex: 1 }}>
            {data.map((g) => (
              <div key={g._id}>
                <ListItem
                  className={classes.groupItem}
                  onClick={() => groupClick(g._id)}
                >
                  <ListItemText>
                    <Box display="flex" justifyContent="space-between">
                      <Box display="flex" alignItems="center">
                        <HashIcon stroke={theme.palette.primary.main} />
                        <Typography color="primary" variant="h3">
                          {g.displayGroupName}
                        </Typography>
                      </Box>
                      <ChevronRightRoundedIcon />
                    </Box>
                  </ListItemText>
                </ListItem>
                <Divider component="li" />
              </div>
            ))}
          </List>
        </>
      ) : (
        <Grid
          container
          style={{
            textAlign: 'center',
            height: PAGE_HEIGHT,
            padding: '20px 22px',
          }}
          direction="column"
          justify="center"
          alignItems="center"
        >
          <Grid item>
            <Typography variant="h3" style={{ marginBottom: '24px' }}>
              You&apos;re not in any groups.
            </Typography>
            <Typography>
              Join a group by texting the #group name on the “
              <Link to="/message">send a message</Link>” page or to{' '}
              {ENV.SHORT_CODE} in SMS.
            </Typography>
          </Grid>
        </Grid>
      )}
    </Box>
  );
}
