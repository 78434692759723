import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Slide,
  makeStyles,
  Snackbar,
  Typography,
  Container,
} from '@material-ui/core';
import useEvent from '../../utils/useEvent';
import successOutlined from '../../img/success-outlined.svg';
import { useCurrentUser } from '../../context/UserContext';
import EventDetails from './EventDetails';
import CopyLinkTutorial from './CopyLinkTutorial';
import RSVPForm from './RSVPForm';
import CopyLinkButton from '../Controls/CopyLinkButton';
import { EVENTS, PAGE_HEIGHT, SNACKBAR_DURATION } from '../../constants';
import PageLoader from '../PageLoader';

const useStyles = makeStyles((theme) => ({
  button: {
    marginBottom: '20px',
  },
  snackbar: {
    bottom: 0,
    height: '128px',
    left: 0,
    maxWidth: '512px',
    right: 0,
    width: '100%',
  },
  snackbarContent: {
    backgroundColor: theme.palette.primary.main,
    height: '100%',
    padding: '20px 0',
    width: '100%',
  },
  addToCalendar: {
    backgroundColor: theme.palette.primary.main,
    padding: '20px',
  },
}));

export default function Event() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const { id } = useParams();

  const { user } = useCurrentUser();

  const [snackbarOpen, setSnackbarOpen] = useState(null);
  const [snackbarTransition] = useState(Slide);
  const [locationState] = useState(location.state || {});

  const { fromCreate, fromUpdate } = locationState;

  useEffect(() => {
    setSnackbarOpen(fromCreate || fromUpdate);
  }, [fromCreate, fromUpdate]);

  useEffect(() => {
    // snackbarOpen is null unless explicitly set to false,
    // either through fromCreate/fromUpdate being false, or
    // the snackbar closing
    if (snackbarOpen === false && (fromCreate || fromUpdate)) {
      // Once snackbar is closed, replace the state so we don't
      // show the snackbar on refresh
      history.replace({
        ...history,
        state: { ...history.state, fromCreate: false, fromUpdate: false },
      });
    }
  }, [snackbarOpen, fromCreate, fromUpdate, history]);

  const {
    data: event,
    isLoading: eventLoading,
    refetch: refetchEvent,
  } = useEvent(id);

  if (eventLoading) {
    return <PageLoader />;
  }

  if (!event) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        style={{ height: PAGE_HEIGHT, paddingTop: '200px' }}
      >
        <Typography variant="h2" color="primary">
          Event not found.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          onClick={() => {
            history.push('/events/create');
          }}
        >
          Create Event
        </Button>
      </Box>
    );
  }

  function handleSnackbarClose() {
    setSnackbarOpen(false);
  }

  let snackbarMessage = '';
  if (fromCreate) {
    snackbarMessage = `Congrats! ${event.title} is now live.`;
  } else if (fromUpdate) {
    snackbarMessage = `${event.title} successfully updated!`;
  }

  const isOwner =
    event.users.find((u) => u.id === user?._id)?.role === EVENTS.ROLE.OWNER;

  return (
    <Box>
      {isOwner && fromCreate && <CopyLinkTutorial />}
      {isOwner && (
        <Container>
          <CopyLinkButton
            beforeText="Copy Event Link"
            afterText="Link Copied to Clipboard!"
            url={document.location.href}
            fullWidth
          />
        </Container>
      )}
      <EventDetails event={event} editable={isOwner} />
      {event.rsvp && <RSVPForm event={event} refetchEvent={refetchEvent} />}
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={SNACKBAR_DURATION}
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        TransitionComponent={snackbarTransition}
        classes={{ root: classes.snackbar }}
      >
        <Box
          display="flex"
          alignItems="center"
          flexDirection="column"
          justifyContent="space-around"
          className={classes.snackbarContent}
        >
          <Typography variant="h4" style={{ color: 'white' }}>
            {snackbarMessage}
          </Typography>
          <img src={successOutlined} alt="Event created successfully!" />
        </Box>
      </Snackbar>
    </Box>
  );
}
