import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
} from '@material-ui/core';
import BackButton from '../Controls/BackButton';

export default function SignupIntro({ onSubmit, onPrevious, formValues }) {
  const [intro, setIntro] = useState();

  function handleSubmit(event) {
    event.preventDefault();
    onSubmit({ ...formValues, intro });
  }

  return (
    <Box>
      <FormControl>
        <TextField
          id="intro"
          label="Intro Message"
          value={intro}
          onChange={(event) => setIntro(event.target.value)}
          required
          variant="outlined"
          margin="normal"
          fullWidth
          aria-describedby="intro-helper-text"
          placeholder="Hey! Thanks for following my username. I'll be using this to send updates and alerts."
          multiline
          rows={4}
          InputLabelProps={{ shrink: true }}
        />
        <FormHelperText id="intro-helper-text">
          This message will be sent to anyone that follows your username.
        </FormHelperText>
      </FormControl>
      <Grid container alignItems="center" style={{ marginTop: '50px' }}>
        <Grid item xs={6} style={{ marginTop: '10px' }}>
          <BackButton fullWidth onClick={onPrevious} />
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            aria-label="next step"
            fullWidth
            onClick={handleSubmit}
            disabled={!intro}
          >
            Sign Up
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

SignupIntro.propTypes = {
  onSubmit: PropTypes.func,
  onPrevious: PropTypes.func,
  formValues: PropTypes.object,
};
