import { useEffect, useState } from 'react';
import { ENV } from '../../constants';
import { authenticatedGet } from '../../utils/authenticatedFetch';

export default function useGroup(id) {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});

  useEffect(() => {
    const controller = new AbortController();
    setLoading(true);

    (async () => {
      try {
        const response = await authenticatedGet(
          `${ENV.API_URL}/group/${id}/data`,
          { signal: controller.signal }
        );

        const groupResponse = await response.json();
        if (!groupResponse.group) {
          throw new Error();
        }

        setData(groupResponse.group);
        setLoading(false);
      } catch (err) {
        console.error(err);
        if (err.name !== 'AbortError') {
          setError(true);
          setLoading(false);
        }
      }
    })();

    return () => {
      setLoading(false);
      controller.abort();
    };
  }, [id, setLoading, setError]);

  const setGroup = (group = null) => {
    if (typeof group === 'function') {
      return setData(group(data));
    }

    return setData(group);
  };

  return [{ error, loading, data }, setGroup];
}
