import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/core';
import DayjsUtils from '@date-io/dayjs';
import { SnackbarProvider } from 'notistack';
import Router from './Router';
import { UserProvider } from '../context/UserContext';
import runConfig from '../config';

const config = runConfig();
const queryClient = new QueryClient();

export default function App() {
  return (
    <div style={{ backgroundColor: '#f2fffd' }}>
      <CssBaseline />
      <QueryClientProvider client={queryClient}>
        <MuiPickersUtilsProvider utils={DayjsUtils}>
          <ThemeProvider theme={config.mui.theme}>
            <SnackbarProvider>
              <UserProvider>
                <Router />
              </UserProvider>
            </SnackbarProvider>
          </ThemeProvider>
        </MuiPickersUtilsProvider>
      </QueryClientProvider>
    </div>
  );
}
