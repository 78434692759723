import { createMuiTheme } from '@material-ui/core';

const primaryMain = '#2B2F51';
const secondaryMain = '#8afcda';
const primaryMainDisabled = '#9597a8';
const secondaryMainDisabled = '#c6feed';
const secondaryLight = '#dcf7f4';
const secondaryLightDisabled = '#f2fffd';
const errorMain = '#c83c3c';
const successMain = '#097152';
const white = '#FFFFFF';
const gray = '#f5f5f5';
const darkGray = '#696F6E';
const darkerGray = '#4f5453';

const h6FontSize = 14;

export default function muiConfig() {
  return {
    theme: createMuiTheme({
      palette: {
        primary: {
          main: primaryMain,
          disabled: primaryMainDisabled,
        },
        secondary: {
          main: secondaryMain,
          disabled: secondaryMainDisabled,
          light: secondaryLight,
          lightDisabled: secondaryLightDisabled,
        },
        error: {
          main: errorMain,
        },
        success: {
          main: successMain,
        },
        gray: {
          main: gray,
          dark: darkGray,
          darker: darkerGray,
        },
        white: {
          main: white,
        },
        text: {
          primary: primaryMain,
        },
      },
      typography: {
        fontFamily: 'Poppins',
      },
      overrides: {
        MuiContainer: {
          root: {
            margin: '20px',
            marginLeft: '22px',
            marginRight: '22px',
            padding: 0,
            paddingLeft: 0,
            paddingRight: 0,
            width: 'unset',
          },
        },
        MuiTooltip: {
          tooltip: {
            color: primaryMain,
            backgroundColor: secondaryLight,
          },
          arrow: {
            color: secondaryLight,
          },
        },
        MuiTypography: {
          h1: {
            fontSize: '32px',
            fontWeight: '700',
            lineHeight: '48px',
            color: primaryMain,
          },
          h2: {
            fontSize: '24px',
            fontWeight: '700',
            lineHeight: '36px',
            color: primaryMain,
          },
          h3: {
            fontSize: '18px',
            fontWeight: '600',
            lineHeight: '27px',
            color: primaryMain,
          },
          h4: {
            fontSize: '16px',
            fontWeight: '600',
            lineHeight: '24px',
            color: primaryMain,
          },
          h5: {
            fontSize: '16px',
            fontWeight: '500',
            lineHeight: '24px',
            color: primaryMain,
          },
          // Small Body Bold
          h6: {
            fontSize: `${h6FontSize}px`,
            fontWeight: '600',
            lineHeight: '19.04px',
            color: primaryMain,
          },
          // Label Accent
          subtitle1: {
            fontSize: '20px',
            fontWeight: '700',
            letterSpacing: '0.16em',
            lineHeight: '30px',
            textTransform: 'uppercase',
          },
          // Label Accent
          subtitle2: {
            fontSize: '20px',
            fontWeight: '700',
            lineHeight: '30px',
          },
          body1: {
            fontSize: '16px',
            fontWeight: '400',
            lineHeight: '21.76px',
          },
          // Small Body
          body2: {
            fontSize: '14px',
            fontWeight: '400',
            lineHeight: '16.8px',
          },
        },
        MuiSvgIcon: {
          fontSizeSmall: {
            fontSize: '14px',
          },
        },
        MuiButton: {
          root: {
            borderRadius: 'none',
            fontSize: '16px',
            fontWeight: '600',
            height: '40px',
            lineHeight: '24px',
            textTransform: 'none',
            width: '160px',
          },
          outlined: {
            border: `1px solid ${primaryMain}`,
          },
          contained: {
            color: 'white',
            backgroundColor: primaryMain,
            '&:focus': {
              color: 'white',
              backgroundColor: primaryMain,
            },
            '&:hover': {
              color: 'white',
              backgroundColor: primaryMain,
              '@media (hover: none)': {
                backgroundColor: primaryMain,
              },
            },
            '&:disabled': {
              color: 'white',
              backgroundColor: primaryMainDisabled,
            },
          },
          containedPrimary: {
            backgroundColor: secondaryMain,
            color: primaryMain,
            '&:hover': {
              backgroundColor: secondaryMain,
              color: primaryMain,
              '@media (hover: none)': {
                backgroundColor: `${secondaryMain} !important`,
                color: `${primaryMain} !important`,
              },
            },
            '&:focus': {
              backgroundColor: secondaryMain,
              color: primaryMain,
            },
            '&:disabled': {
              backgroundColor: secondaryMainDisabled,
              color: primaryMainDisabled,
            },
          },
          containedSecondary: {
            backgroundColor: secondaryLight,
            color: primaryMain,
            '&:hover': {
              backgroundColor: secondaryLight,
              color: primaryMain,
            },
            '&:focus': {
              backgroundColor: secondaryLight,
              color: primaryMain,
            },
            '&:active': {
              backgroundColor: secondaryLight,
              color: primaryMain,
            },
            '&:disabled': {
              backgroundColor: secondaryLight,
              color: primaryMainDisabled,
            },
          },
        },
        MuiCard: {
          root: {
            backgroundColor: 'white',
          },
        },
        MuiCardContent: {
          root: {
            '&:last-child': {
              paddingBottom: '16px',
            },
          },
        },
        MuiListItemText: {
          // H3
          primary: {
            fontSize: '18px',
            fontWeight: '600',
            lineHeight: '27px',
          },
        },
        MuiPickersTimePickerToolbar: {
          hourMinuteLabel: {
            alignItems: 'initial',
          },
          ampmLabel: {
            lineHeight: '32px',
          },
        },
        MuiPickersToolbarButton: {
          toolbarBtn: {
            height: 'auto',
            width: 'auto',
          },
        },
        MuiPickersToolbarText: {
          toolbarTxt: {
            fontSize: '60px',
            fontWeight: 500,
            lineHeight: '60px',
          },
        },
        // MUI does a scale of 0.75 to shrink the label
        // So set the fontSize to 1.333 of the desired font size
        MuiInputLabel: {
          shrink: {
            fontSize: `${(h6FontSize * 4) / 3}px`,
          },
        },
        MuiOutlinedInput: {
          root: {
            fontSize: `${(h6FontSize * 4) / 3}px`,
          },
        },
      },
    }),
  };
}
