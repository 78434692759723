const SIGNUP = {
  DEFAULT: 'Error on signup.',
  NO_SESSION: 'This is an invalid signup link.',
  CREATE_FAILURE: 'There was an error creating your user profile.',
};

const CREATE_HASHTAG = {
  DEFAULT: 'Error while creating hashtag.',
  NO_SESSION: 'This is an invalid link.',
  CREATE_FAILURE: 'There was an error creating the hashtag.',
};

const GROUP_VIEW = {
  NOT_FOUND: 'Invalid group Id',
};

export default {
  SIGNUP,
  CREATE_HASHTAG,
  GROUP_VIEW,
  DEFAULT: 'There was an error.',
};
