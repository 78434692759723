const getDateForComparison = (date) =>
  365 * date.getYear() + 12 * (date.getMonth() + 1) + date.getDate();

export default (date, today = new Date()) => {
  const dateObject = new Date(date);

  const diff = getDateForComparison(today) - getDateForComparison(dateObject);

  // If today, print the time
  if (diff === 0) {
    return dateObject.toLocaleTimeString('en-us', {
      hour: 'numeric',
      minute: '2-digit',
    });
  }

  // If yesterday, print 'Yesterday'
  if (diff === 1) {
    return 'Yesterday';
  }

  // If less than a week ago, only print the day of the week
  if (diff < 7) {
    return dateObject.toLocaleString('en-us', {
      weekday: 'long',
    });
  }

  return dateObject.toLocaleString('en-us', {
    weekday: 'long',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });
};

export function fullDate(date) {
  const dateObject = new Date(date);
  return dateObject.toLocaleString('en-us', {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
  });
}
