// Built with help from https://kentcdodds.com/blog/how-to-use-react-context-effectively

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import { useSnackbar } from 'notistack';
import { authenticatedGet } from '../utils/authenticatedFetch';
import { ENV } from '../constants';

const UserContext = React.createContext({
  loading: true,
  user: null,
  loadUser: () => {},
});

export function UserProvider({ children }) {
  const { enqueueSnackbar } = useSnackbar();

  const query = useQuery(
    'currentUser',
    async () => {
      const response = await authenticatedGet(`${ENV.API_URL}/user`);

      if (response.status === 401) {
        return null;
      }
      if (!response.ok) {
        throw Error('Error retrieving user data');
      }

      const result = await response.json();

      return result.user;
    },
    {
      onError(response) {
        enqueueSnackbar(response.message, { variant: 'error' });
      },
    }
  );

  return (
    <UserContext.Provider
      value={{
        ...query,
        loading: query.isLoading,
        user: query.data,
        loadUser: query.refetch,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export function useCurrentUser() {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useCurrentUser must be used within a UserProvider');
  }
  return context;
}

UserProvider.propTypes = {
  children: PropTypes.any,
};
