import React from 'react';
import { useLocation } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import { ERRORS } from '../../constants';
import ErrorPage, { ErrorMessage, ErrorBack, ErrorButtons } from '../ErrorPage';

export default function SignupError() {
  const location = useLocation();
  return (
    <ErrorPage>
      <ErrorMessage message={location.state?.error || ERRORS.SIGNUP.DEFAULT} />
      <Typography variant="h4" color="primary">
        Press Back to try again.
      </Typography>
      <ErrorButtons>
        <ErrorBack />
      </ErrorButtons>
    </ErrorPage>
  );
}
