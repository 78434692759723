import React from 'react';
import PropTypes from 'prop-types';
import { Box, makeStyles, Typography, useTheme } from '@material-ui/core';
import { EVENTS } from '../../../constants';
import { useCurrentUser } from '../../../context/UserContext';
import RSVPSignUp from './RSVPSignUp';
import RSVPYesIcon from '../../Icons/RSVPYes';
import RSVPMaybeIcon from '../../Icons/RSVPMaybe';
import RSVPNoIcon from '../../Icons/RSVPNo';

const useStyles = makeStyles((theme) => ({
  main: {
    backgroundColor: theme.palette.primary.main,
    padding: '35px 22px',
    textAlign: 'center',
  },
  icon: {
    margin: '12px 0',
    stroke: theme.palette.secondary.main,
    fill: 'none',
    fontSize: '50px',
  },
  button: {
    alignItems: 'center',
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.main,
    display: 'flex',
    height: '40px',
    justifyContent: 'center',
    marginTop: '20px',
    textDecoration: 'none !important',
    width: '100%',
  },
}));

export default function RSVPConfirmation({ data }) {
  const classes = useStyles();
  const theme = useTheme();
  const { user } = useCurrentUser();

  let title = '';
  const subtitle = data.isUpdate
    ? 'Thanks for keeping us updated!'
    : 'Thanks for the RSVP.';
  let Icon = null;
  switch (data.status) {
    case EVENTS.STATUS.YES:
      Icon = RSVPYesIcon;
      title = "Great! Can't wait to see you there.";
      break;
    case EVENTS.STATUS.MAYBE:
      Icon = RSVPMaybeIcon;
      title = 'Let us know when you decide.';
      break;
    case EVENTS.STATUS.NO:
      Icon = RSVPNoIcon;
      title = "We're sorry you can't make it.";
      break;
    default:
      break;
  }
  return (
    <Box>
      <Box className={classes.main}>
        {Icon && <Icon className={classes.icon} />}
        <Typography
          style={{ color: theme.palette.secondary.main }}
          variant="h3"
        >
          {title}
        </Typography>
        <Typography
          style={{ color: theme.palette.secondary.main }}
          variant="body1"
        >
          {subtitle}
        </Typography>
      </Box>
      {!user?.handle && <RSVPSignUp />}
    </Box>
  );
}

RSVPConfirmation.propTypes = {
  data: PropTypes.shape({
    status: PropTypes.oneOf(Object.values(EVENTS.STATUS)),
    isUpdate: PropTypes.bool,
    eventId: PropTypes.string,
  }),
};
