import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, makeStyles, Typography, useTheme } from '@material-ui/core';
import AmploSwitch from '../Controls/AmploSwitch';
import UserAvatar from '../GroupView/UserAvatar';
import displayHandle from '../../utils/displayHandle';

const useStyles = makeStyles({
  main: {
    backgroundColor: 'white',
    padding: '0 20px 20px 20px',
  },
  allowReply: {},
  followerRow: {
    padding: '12px 0',
    borderBottom: '1px solid #bfc4c3',
  },
  otherFollowers: {
    color: '#696f6e',
    fontSize: '16px',
    textAlign: 'center',
    marginTop: '33px',
  },
});

export default function FollowerList({
  defaultAllowReply,
  defaultSettingTip,
  followers,
  unregisteredFollowers,
  onDefaultAllowReplyChange,
  savingDefault,
  onFollowerAllowReplyChange,
  savingFollower,
  readOnly = false,
}) {
  const classes = useStyles();
  const theme = useTheme();

  const totalFollowerCount = followers.length + unregisteredFollowers.length;

  const plural = unregisteredFollowers.length !== 1 ? 's' : '';
  const article = unregisteredFollowers.length === 1 ? 'a ' : '';
  const otherFollowers =
    unregisteredFollowers.length > 0 ? (
      <Typography
        className={classes.otherFollowers}
      >{`${unregisteredFollowers.length} other${plural} without ${article}username${plural}`}</Typography>
    ) : null;

  return (
    <Box className={classes.main}>
      <Grid container alignItems="flex-end">
        <Grid item xs={6}>
          <Typography color="primary" variant="h3">
            {totalFollowerCount} Follower{totalFollowerCount !== 1 ? 's' : ''}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          {!readOnly && <Typography
            variant="h5"
            style={{
              color: theme.palette.gray.dark,
              textAlign: 'right',
            }}
          >
            Allow Reply
          </Typography>}
        </Grid>
      </Grid>
      {!readOnly && <Grid container justify="space-between" alignItems="center">
        <Grid item xs={10}>
          <Typography color="primary" variant="h5">
            Default Reply Setting
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <AmploSwitch
            checked={defaultAllowReply}
            disabled={savingDefault}
            onChange={(event) =>
              onDefaultAllowReplyChange(event.target.checked)
            }
          />
        </Grid>
      </Grid>}
      <Grid container justify="space-between" alignItems="center">
        {!readOnly && <Grid item xs={10}>
          <Typography
            variant="body2"
            style={{ marginBottom: '13px', color: theme.palette.gray.dark }}
          >
            {defaultSettingTip}
          </Typography>
        </Grid>}

        {followers.map((f) => (
          <Grid
            container
            justify="space-between"
            alignItems="center"
            className={classes.followerRow}
            key={f._id}
          >
            <Grid item xs={10}>
              <Grid container alignItems="center">
                <Grid item>
                  <UserAvatar user={f} style={{ margin: '0 10px 0 16px' }} />
                </Grid>
                <Grid item xs>
                  <Typography variant="body1">
                    {displayHandle(f, {
                      fields: [
                        'displayHandle',
                        'handle',
                        'firstName',
                        'lastName',
                      ],
                      backupLabel: '@User',
                    })}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {!readOnly && <Grid item xs={2}>
              <AmploSwitch
                checked={f.allowReply}
                disabled={savingFollower}
                onChange={(event) =>
                  onFollowerAllowReplyChange(f._id, event.target.checked)
                }
              />
            </Grid>}
          </Grid>
        ))}
      </Grid>
      {otherFollowers}
      {/* <Button danger type="ghost">
          Remove a Follower
        </Button> */}
    </Box>
  );
}

const followerShape = {
  _id: PropTypes.string,
  allowReply: PropTypes.bool,
  handle: PropTypes.string,
  displayHandle: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
};

FollowerList.propTypes = {
  defaultAllowReply: PropTypes.bool,
  defaultSettingTip: PropTypes.string,
  followers: PropTypes.arrayOf(PropTypes.shape(followerShape)),
  unregisteredFollowers: PropTypes.arrayOf(PropTypes.shape(followerShape)),
  onDefaultAllowReplyChange: PropTypes.func,
  savingDefault: PropTypes.bool,
  onFollowerAllowReplyChange: PropTypes.func,
  savingFollower: PropTypes.bool,
  readOnly: PropTypes.bool,
};
