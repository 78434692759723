import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ENV } from '../constants';
import { useCurrentUser } from '../context/UserContext';
import { authenticatedPost } from '../utils/authenticatedFetch';

export default function Logout() {
  const history = useHistory();
  const { user, loadUser } = useCurrentUser();

  useEffect(() => {
    const controller = new AbortController();

    async function logout() {
      try {
        if (user) {
          await authenticatedPost(
            `${ENV.API_URL}/logout`,
            {},
            {
              signal: controller.signal,
            }
          );
          loadUser();
        }
      } catch (err) {
        console.error(err);
      } finally {
        history.push('/');
      }
    }

    logout();

    return () => {
      controller.abort();
    };
  }, [history, loadUser, user]);

  return <React.Fragment />;
}
