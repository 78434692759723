import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import {
  CreateEvent,
  Event,
  EventAttendees,
  Events,
  Home,
  Login,
  Logout,
  MagicLink,
  MagicCode,
  Signup,
  SignupSuccess,
  SignupError,
  Group,
  GroupList,
  GroupCreate,
  User,
  QuickGuides,
  FAQ,
  Support,
  SendMessage,
} from '.';
import Footer from './Footer';
import Nav from './Nav';
import ScrollToTop from './ScrollToTop';
import PrivateRoute from './PrivateRoute';

// Params are placeholders in the URL that begin
// with a colon, like the `:id` param defined in
// the route in this example. A similar convention
// is used for matching dynamic segments in other
// popular web frameworks like Rails and Express.
export default function Router() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Nav />
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/magic/link/:code">
          <MagicLink />
        </Route>
        <Route exact path="/magic/code">
          <MagicCode />
        </Route>
        <Route exact path="/login">
          <Login />
        </Route>
        <Route exact path="/logout">
          <Logout />
        </Route>
        <Route path="/signup/success">
          <SignupSuccess />
        </Route>
        <Route path="/signup/error">
          <SignupError />
        </Route>
        <Route path="/signup">
          <Signup />
        </Route>
        <PrivateRoute path="/events/create">
          <CreateEvent />
        </PrivateRoute>
        <PrivateRoute path="/events/update/:id">
          <CreateEvent />
        </PrivateRoute>
        <Route path="/events/event/:id/attendees">
          <EventAttendees />
        </Route>
        <Route path="/events/event/:id">
          <Event />
        </Route>
        <PrivateRoute path="/events">
          <Events />
        </PrivateRoute>
        <Route path="/quick-guides">
          <QuickGuides />
        </Route>
        <Route path="/faq">
          <FAQ />
        </Route>
        <Route path="/support">
          <Support />
        </Route>
        <PrivateRoute path="/group/create">
          <GroupCreate />
        </PrivateRoute>
        <PrivateRoute path="/group/:id">
          <Group />
        </PrivateRoute>
        <PrivateRoute path="/user/:id/groups">
          <GroupList />
        </PrivateRoute>
        <PrivateRoute path="/user/:id">
          <User />
        </PrivateRoute>
        <PrivateRoute path="/message">
          <SendMessage />
        </PrivateRoute>
        <Route>
          <Redirect to="/" />
        </Route>
      </Switch>
      <Footer />
    </BrowserRouter>
  );
}
