import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Grid, Typography, useTheme } from '@material-ui/core';
import MessagerView from '../Messages/MessagerView';
import { ENV, PAGE_HEIGHT } from '../../constants';
import { authenticatedGet } from '../../utils/authenticatedFetch';

export default function GroupView({ group }) {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [messages, setMessages] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  function handleHeaderClick() {
    history.push(`${history.location.pathname}/details`);
  }

  // TODO - can probably extract this into a useMessages hook, similar to useFollowers
  useEffect(() => {
    const controller = new AbortController();

    if (group._id) {
      setLoading(true);

      (async () => {
        try {
          const response = await authenticatedGet(
            `${ENV.API_URL}/group/${group._id}/messages`,
            { signal: controller.signal }
          );
          const json = await response.json();

          if (!json.success) {
            throw new Error();
          }

          setMessages(json.messages);
          setLoading(false);
        } catch (err) {
          console.error(err);
          if (err.name !== 'AbortError') {
            enqueueSnackbar('Failed to load messages', { variant: 'error' });
            setLoading(false);
          }
        }
      })();
    }
    return () => {
      controller.abort();
      setLoading(false);
    };
  }, [group, setLoading, enqueueSnackbar]);

  return (
    <MessagerView
      name={`#${group.displayGroupName}`}
      messages={messages}
      loading={loading}
      headerClick={handleHeaderClick}
      emptyContent={
        <Grid
          container
          style={{
            textAlign: 'center',
            height: PAGE_HEIGHT,
            padding: '20px 22px',
          }}
          direction="column"
          justify="center"
          alignItems="center"
        >
          <Grid item>
            <Typography
              variant="h4"
              style={{ marginBottom: '24px', color: theme.palette.gray.dark }}
            >
              This group doesn&apos;t have any messages.
            </Typography>
          </Grid>
        </Grid>
      }
    />
  );
}

GroupView.propTypes = {
  group: PropTypes.shape({
    _id: PropTypes.string,
    groupName: PropTypes.string,
    displayGroupName: PropTypes.string,
    intro: PropTypes.string,
    followers: PropTypes.arrayOf(PropTypes.object),
    defaultAllowReply: PropTypes.bool,
    createdBy: PropTypes.string,
  }),
};
