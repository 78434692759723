import React from 'react';
import PropTypes from 'prop-types';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { Box, makeStyles, Tooltip, Typography } from '@material-ui/core';
import clsx from 'clsx';
import Linkify from 'react-linkify';
import prettyPrintDate, { fullDate } from '../../utils/prettyPrintDate';
import displayHandle from '../../utils/displayHandle';

const formatMessageText = (handle, text, userIsSender, isPrivate) => {
  let sender = !userIsSender ? `@${handle}: ` : '';
  if (isPrivate) {
    sender = <b>{sender}</b>;
  }
  return (
    <Linkify>
      {sender}
      {text}
    </Linkify>
  );
};

const useStyles = makeStyles((theme) => ({
  main: {
    padding: '8px 0',
    maxWidth: '40em',
    margin: '0 auto',
  },
  font: {
    fontFamily: '"Poppins", Arial, sans-serif',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '120%',
    color: '#696F6E',
  },
  timestamp: {
    height: '17px',
    width: '100%',
    padding: '2px 10px 0 10px',
    textAlign: 'right',
  },
  leftAlign: {
    textAlign: 'left',
  },
  onlyYou: {
    marginBottom: '2px',
    display: 'flex',
    alignItems: 'center',
  },
  messageBoxStyle: {
    padding: '10px 15.5px',
    boxShadow: '0px 2px 9px rgba(0, 0, 0, 0.17)',
    borderRadius: '2px',
    color: theme.palette.primary.main,
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
  },
  selfMessageStyle: {
    background: '#DCF7F4',
  },
  tooltip: {
    backgroundColor: 'white',
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
  },
  media: {
    maxWidth: '100%',
    marginBottom: '5px',
  },
}));

function getOtherUser(sender, recipient, userIsSender) {
  if (!userIsSender) return sender ? sender.firstName : 'the sender';

  if (!recipient) return 'the recipient';

  return recipient.firstName ? recipient.firstName : recipient.displayHandle;
}

export default function Message({
  sender,
  recipient,
  text,
  mediaUrls,
  sentAt,
  userIsSender,
  isPrivate,
}) {
  const classes = useStyles();

  const otherUser = isPrivate
    ? getOtherUser(sender, recipient, userIsSender)
    : '';

  return (
    <Box className={classes.main}>
      {isPrivate && (
        <Box className={clsx(classes.font, classes.onlyYou)}>
          <VisibilityOutlinedIcon style={{ fontSize: 13, marginRight: 5 }} />
          Only you and {otherUser} can see this message.
        </Box>
      )}
      <Box
        className={clsx(
          classes.messageBoxStyle,
          userIsSender && classes.selfMessageStyle
        )}
      >
        {mediaUrls?.map((mediaUrl) => (
          <img
            src={mediaUrl}
            key={mediaUrl}
            alt="Sent Media"
            className={classes.media}
          />
        ))}
        <Typography variant="body1" color="primary">
          {formatMessageText(
            displayHandle(sender, { backupLabel: 'Them' }),
            text,
            userIsSender,
            isPrivate
          )}
        </Typography>
      </Box>
      <Box
        className={clsx(
          classes.font,
          classes.timestamp,
          !userIsSender && classes.leftAlign
        )}
      >
        <Tooltip
          classes={{
            tooltip: classes.tooltip,
          }}
          placement="left"
          style={{ display: 'inline' }}
          title={<Typography variant="body2">{fullDate(sentAt)}</Typography>}
        >
          <Typography variant="body2">{prettyPrintDate(sentAt)}</Typography>
        </Tooltip>
      </Box>
    </Box>
  );
}

Message.propTypes = {
  sender: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    displayHandle: PropTypes.string,
  }),
  recipient: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    displayHandle: PropTypes.string,
  }),
  text: PropTypes.string,
  sentAt: PropTypes.string,
  userIsSender: PropTypes.bool,
  isPrivate: PropTypes.bool,
  mediaUrls: PropTypes.arrayOf(PropTypes.string),
};
