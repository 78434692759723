// TODO - we may want to grab these values from the API
// Instead of hardcoding
const ROLE = {
  OWNER: 'OWNER',
  GUEST: 'GUEST',
};

const STATUS = {
  YES: 'YES',
  MAYBE: 'MAYBE',
  NO: 'NO',
};

export default {
  ROLE,
  STATUS,
};
