import { SvgIcon } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as Phone } from '../../img/phone.svg';

export default function PhoneIcon(props) {
  const { style, ...rest } = props;
  return (
    <SvgIcon
      component={Phone}
      viewBox="0 0 32 32"
      style={{ color: 'rgba(0,0,0,0)', ...style }}
      {...rest}
    />
  );
}

PhoneIcon.propTypes = {
  style: PropTypes.any,
};
