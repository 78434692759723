import React from 'react';
import PropTypes from 'prop-types';
import { Grid, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '9px',
    backgroundColor: '#dfe2e1',
  },
  filled: {
    backgroundColor: theme.palette.secondary.main,
  },
  container: {
    width: '100%',
    borderRadius: '4px',
    overflow: 'hidden',
  },
}));

export default function AmploSteps(props) {
  const { currentStep, stepCount, ...rest } = props;
  const range = Array.from(Array(stepCount));
  const classes = useStyles();
  return (
    <Grid container className={classes.container} {...rest}>
      {range.map((_, i) => (
        <Grid key={i} item xs>
          <div
            className={clsx(classes.root, i <= currentStep && classes.filled)}
          />
        </Grid>
      ))}
    </Grid>
  );
}

AmploSteps.propTypes = {
  currentStep: PropTypes.number,
  stepCount: PropTypes.number,
};
