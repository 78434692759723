import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Button, Grid, Typography, useTheme } from '@material-ui/core';
import { ENV, PAGE_HEIGHT } from '../../constants';
import MessagerView from '../Messages/MessagerView';
import displayHandle from '../../utils/displayHandle';

function createMessagesLists(messages) {
  const sent = messages.filter((m) => !m.recipientUserId);
  const dms = messages.filter((m) => m.recipientUserId);

  return [
    {
      type: 'Sent Messages',
      messages: sent,
    },
    {
      type: 'Direct Messages',
      messages: dms,
      isPrivate: true,
    },
  ];
}

export default function UserView({ user }) {
  const [loading, setLoading] = useState(true);
  const [messages, setMessages] = useState([]);
  const history = useHistory();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  function handleViewFollowers() {
    history.push(`${history.location.pathname}/details`);
  }

  useEffect(() => {
    let mounted = true;

    if (user) {
      setLoading(true);

      (async () => {
        try {
          const response = await fetch(
            `${ENV.API_URL}/user/${user._id}/messages`,
            {
              method: 'GET',
              credentials: 'include',
            }
          );
          const json = await response.json();

          if (mounted) {
            if (!json.success) {
              throw new Error();
            }

            setMessages(createMessagesLists(json.messages));
            setLoading(false);
          }
        } catch (err) {
          if (mounted) {
            enqueueSnackbar('Failed to load messages', { variant: 'error' });
            setLoading(false);
          }
        }
      })();
    }
    return () => {
      mounted = false;
      setLoading(false);
    };
  }, [user, setLoading, enqueueSnackbar]);

  function onEditClick() {
    history.push(`/user/${user._id}/details/edit`);
  }

  return (
    <MessagerView
      name={`${displayHandle(user, { backupLabel: 'Profile' })}`}
      photo={user.photo}
      showTabs
      messages={messages}
      loading={loading}
      headerClick={handleViewFollowers}
      emptyContent={
        <Grid
          container
          style={{
            textAlign: 'center',
            height: PAGE_HEIGHT,
            padding: '20px 22px',
          }}
          direction="column"
          justify="center"
          alignItems="center"
        >
          <Grid item>
            <Typography
              variant="h4"
              style={{ marginBottom: '24px', color: theme.palette.gray.dark }}
            >
              You don&apos;t have any messages.
            </Typography>
          </Grid>
          {!user.handle && (
            <Grid item>
              <Typography
                variant="h3"
                style={{ marginBottom: '24px', color: theme.palette.gray.dark }}
              >
                To start sending messages, add a username to your profile.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                disableElevation
                aria-label="Edit my Profile"
                fullWidth
                onClick={onEditClick}
              >
                Edit my Profile
              </Button>
            </Grid>
          )}
        </Grid>
      }
    />
  );
}

UserView.propTypes = {
  user: PropTypes.shape({
    _id: PropTypes.string,
    displayHandle: PropTypes.string,
    handle: PropTypes.string,
    photo: PropTypes.string,
  }),
};
