import { LoadingOutlined } from '@ant-design/icons';
import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { BOTTOM_BAR_OFFSET, PAGE_HEIGHT } from '../constants';
import { useCurrentUser } from '../context/UserContext';

const useStyles = makeStyles(() => ({
  main: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: PAGE_HEIGHT,
    padding: `20px 22px ${BOTTOM_BAR_OFFSET}`,
  },
  button: {
    marginTop: '24px',
  },
  intro: {
    marginTop: '16px',
  },
}));

export default function Home() {
  const history = useHistory();
  const { user, loading } = useCurrentUser();

  const classes = useStyles();

  function onEditClick() {
    history.push(`/user/${user._id}/details/edit`);
  }

  let content = (
    <Box className={classes.main}>
      <Button
        key="Sign-Up"
        variant="contained"
        disableElevation
        color="primary"
        className={classes.button}
        onClick={() => history.push('/signup')}
      >
        Sign-Up
      </Button>
      <Button
        key="Login"
        variant="contained"
        disableElevation
        color="secondary"
        className={classes.button}
        onClick={() => history.push('/login')}
      >
        Login
      </Button>
    </Box>
  );

  if (loading) {
    content = <LoadingOutlined />;
  } else if (user && !user.handle) {
    content = (
      <Box className={classes.main}>
        <Typography color="primary" variant="h2" align="center">
          Welcome to amplo!
        </Typography>
        <Typography
          color="primary"
          variant="h5"
          align="center"
          className={classes.intro}
        >
          To get the most out of amplo, complete your profile and add a
          username.
        </Typography>
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          disableElevation
          aria-label="Edit my Profile"
          fullWidth
          onClick={onEditClick}
        >
          Edit my Profile
        </Button>
      </Box>
    );
  } else if (user) {
    content = (
      <Box className={classes.main}>
        <Typography color="primary" variant="h2" align="center">
          Welcome back, {user.firstName}!
        </Typography>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => history.push(`/user/${user._id}/groups`)}
            disableElevation
            aria-label="go to Groups"
          >
            Groups
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => history.push('/events')}
            disableElevation
            aria-label="go to Events"
          >
            Events
          </Button>
        </Box>
      </Box>
    );
  }
  return content;
}
