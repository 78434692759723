import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import { ENV, EVENTS } from '../constants';
import { get } from './fetch';

export default function useEvent(id) {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery(
    `eventData.${id}`,
    async () => {
      const response = await get(`${ENV.API_URL}/events/event/${id}`);
      if (!response.ok) {
        throw new Error('Failed to fetch event');
      }

      const result = await response.json();

      return result.data;
    },
    {
      enabled: Boolean(id),
      onError: (response) => {
        enqueueSnackbar(response.message, { variant: 'error' });
      },
    }
  );
}

export const EventPropTypes = PropTypes.shape({
  _id: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  location: PropTypes.string,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      role: PropTypes.oneOf(Object.values(EVENTS.ROLE)),
      status: PropTypes.oneOf([...Object.values(EVENTS.STATUS), null]),
    })
  ),
  startTime: PropTypes.string,
  endTime: PropTypes.string,
  rsvp: PropTypes.bool,
});
