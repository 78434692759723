import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Tab, Tabs, withStyles } from '@material-ui/core';

const StyledTabs = withStyles({
  indicator: {
    height: '6px',
  },
})((props) => <Tabs {...props} />);

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    fontSize: '16px',
    fontWeight: '600',
    opacity: '.7',
    color: theme.palette.primary.main,
  },
  selected: {
    opacity: '1',
  },
}))((props) => <Tab {...props} />);

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: 'white',
    backdropFilter: 'blur(8px)',
  },
}));

export default function AmploTabs({ tabs, onChange }) {
  const classes = useStyles();
  const [index, setIndex] = useState(0);

  const handleChange = (event, newValue) => {
    setIndex(newValue);
    onChange(newValue);
  };

  return (
    <div className={classes.root}>
      <StyledTabs
        value={index}
        onChange={handleChange}
        variant="fullWidth"
        indicatorColor="secondary"
      >
        {tabs.map((t) => (
          <StyledTab key={t} label={t} />
        ))}
      </StyledTabs>
    </div>
  );
}

AmploTabs.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func,
};
