import { SvgIcon } from '@material-ui/core';
import React from 'react';

export default function RSVPYes(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M16 21V19C16 17.9391 15.5786 16.9217 14.8284 16.1716C14.0783 15.4214 13.0609 15 12 15H5C3.93913 15 2.92172 15.4214 2.17157 16.1716C1.42143 16.9217 1 17.9391 1 19V21"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.5 11C10.7091 11 12.5 9.20914 12.5 7C12.5 4.79086 10.7091 3 8.5 3C6.29086 3 4.5 4.79086 4.5 7C4.5 9.20914 6.29086 11 8.5 11Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.8571 8.574C18.9915 8.19206 19.2567 7.87006 19.6057 7.66491C19.9547 7.45977 20.3651 7.3848 20.7641 7.45326C21.1631 7.52171 21.525 7.72914 21.7858 8.03886C22.0465 8.34857 22.1892 8.74057 22.1886 9.14543C22.1886 10.2883 20.4743 10.8597 20.4743 10.8597"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.5201 13.1457H20.5301"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
