import usernameMessage from '../img/faq/username-message.png';
import groupMessage from '../img/faq/group-message.png';
import ENV from './env';

const FAQ_USERNAME = [
  {
    title: 'What is a username?',
    body:
      'A username is created when someone signs up. People can receive messages from someone by following their username. ',
  },
  {
    title: 'How do people follow my username?',
    body: `People can follow your username by texting your username (including the @ symbol) to ${ENV.SHORT_CODE}. `,
  },
  {
    title: 'How do I send messages to people who follow my username?',
    body: `You can send messages to your followers simply by texting your message to ${ENV.SHORT_CODE}. There is no need to include the username in your message. Anyone who is following your username will receive this message.`,
    image: usernameMessage,
    imageAlt: 'send message to followers example',
  },
  {
    title: "What's a Direct Message? How do I respond to one?",
    body: `A direct message is only between 2 usernames. Anyone with your username can send you a direct message. A direct message to you includes your @username in it and only you can see this message. You can respond to direct messages by including their username in your message to ${ENV.SHORT_CODE}. Only the username you include in your message will be able to see the message.`,
  },
  // {
  //   title: 'Can I control who can send me a direct message?',
  //   body:
  //     "Yes, you can decide who can send you direct messages by toggling the switch next to the user's name on your username followers page. There is a default reply switch that will automatically be applied to any new followers.",
  // },
  {
    title: "Why can't I see some of my followers' names?",
    body: `People can follow your username without creating a username. They will receive your username messages but they cannot direct message you. This allows people to be able to stay connected with you through one simple text to ${ENV.SHORT_CODE}, signing up through our website to create a username is an added bonus.`,
  },
];

const FAQ_GROUPS = [
  {
    title: 'What is a group?',
    body:
      'A group is a more collaborative way to stay connected. Several people can send messages to a group (if the creator allows it) and anyone following the group will receive those messages. Only people with usernames can send a message to a group.',
  },
  {
    title: 'How do I send messages to a group?',
    body:
      'You can send messages to a group by including the group name (including the # symbol) at the beginning of your message. Only people following the group will receive this message.',
    image: groupMessage,
    imageAlt: 'send message to group example',
  },
];

const FAQ_FOLLOWING_USERNAME = [
  {
    title: "How do I follow someone's username?",
    body: `You follow someone's username by texting their username (including the @symbol) to ${ENV.SHORT_CODE}. You will receive any texts they send to their username in your ${ENV.SHORT_CODE} text message thread.`,
  },
  {
    title: "How do I unfollow someone's username?",
    body: `You can unfollow someone by texting "UNFOLLOW @[their username]" to ${ENV.SHORT_CODE}.`,
  },
  {
    title: 'How do I direct message a username?',
    body: `You can direct message a username you follow by texting their username (including the @symbol) and your message to ${ENV.SHORT_CODE}. They can reply back to you. People with usernames can control who can send direct messages so this feature may not be available for all usernames.`,
  },
];

const FAQ_FOLLOWING_GROUP = [
  {
    title: 'How do I follow a group?',
    body: `You can follow a group by texting the group name (including the # symbol) to ${ENV.SHORT_CODE}. You will receive any texts sent to this group in your ${ENV.SHORT_CODE} text message thread.`,
  },
  {
    title: 'How do I unfollow a group?',
    body: `You can unfollow a group by texting "UNFOLLOW #[groupname]" to ${ENV.SHORT_CODE}.`,
  },
  {
    title: 'How do I send a message to a group I am following?',
    body:
      'You can send messages to a group you are following by including the group name (including the # symbol) at the beginning of your message. Anyone that is following the group will receive this message. To send a message to a group you are following you have to have a username. Administrators of a group can control who can send messages, so this feature may not be available for all groups.',
  },
];

const FAQ_EVENTS = [
  {
    title: 'How do I create an event?',
    body:
      'Sign in and open the main navigation. Click “events” and then click “new event.” Fill out the form to create your event. ',
  },
  {
    title: 'How do I invite people to the event?',
    body:
      'After you create your event, there is a button at the top of the event page that says “Copy event link.” You can copy the event link and then paste the link in a message to your group or username followers by clicking “send a message” in the top navigation bar. You can also share this event link outside of amplo and people will see the event page when they click the link. ',
  },
  {
    title: 'Can I see who is coming to the event? ',
    body:
      'When creating an event, there is a switch for “RSVP” at the bottom of the page. If you want to see who is coming or not coming to your event, you should switch RSVP on. If you create an event with RSVP, people who receive the event link can RSVP “Yes”, “No”, and “Maybe” and you will be able to see the list from the event page.',
  },
  {
    title: 'Can I send a message to the RSVP list? ',
    body:
      'When you create or edit your event, you can schedule a reminder message to be sent to the event attendees at a time before the event happens. You can only send one of these reminders. ',
  },
  {
    title: 'Can I edit an event after it has been created?',
    body:
      'Yes. On the event page, there is an edit button at the top right corner of the event card. Here you can edit the event or cancel the event.',
  },
];

export default {
  FAQ_USERNAME,
  FAQ_GROUPS,
  FAQ_FOLLOWING_USERNAME,
  FAQ_FOLLOWING_GROUP,
  FAQ_EVENTS,
};
